<!-- <template>
  <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
      {{ messages }}
    </b-alert>
    <div class="page-header">
      <h1>{{ $t("Create-Fac-Man") }}</h1>
    </div>
    <form class="body">
      <div class="containerz">
        <div class="form">
          <div class="left">
            <div class="logo" v-if="!form.logo">
              <span class="textstyle" v-if="form && form.lib">{{
                form.lib[0]
              }}</span>
            </div>
            <div class="logo" v-else>
              <img :src="downloadUrl + form.logo" alt="logo" />
            </div>
            <div
              class="fieldset"
              v-if="isCommercialBatigo || isSuperAdmin"
              v-b-modal.entrepriseModal
            >
              <div class="legend">{{ $t("COMPANY_NAME") + "*" }}</div>
              <div class="input textstyle" v-if="form && form.lib">
                {{ form.lib }}
              </div>
              <div class="input placeholder" v-else>
                {{ $t("COMPANY_NAME") }}
              </div>
            </div>
            <div class="fieldset" v-else>
              <div class="legend">{{ $t("COMPANY_NAME") }}</div>
              <div class="input textstyle" v-if="form && form.lib">
                {{ form.lib }}
              </div>
              <div class="input placeholder" v-else>
                {{ $t("COMPANY_NAME") }}
              </div>
            </div>
            <div class="fieldset">
              <div class="legend">{{ $t("ADRESS") }}</div>
              <div class="input textstyle" v-if="form.rue">{{ form.rue }}</div>
              <div class="input placeholder" v-else>{{ $t("ADRESS") }}</div>
            </div>
            <div class="justify">
              <div class="fieldset">
                <div class="legend">{{ $t("POSTAL_CODE") }}</div>
                <div class="input" v-if="form.cp">{{ form.cp }}</div>
                <div class="input placeholder" v-else>
                  {{ $t("POSTAL_CODE") }}
                </div>
              </div>
              <div class="fieldset">
                <div class="legend">{{ $t("CITY") }}</div>
                <div class="input textstyle" v-if="form.ville">
                  {{ form.ville }}
                </div>
                <div class="input placeholder" v-else>{{ $t("CITY") }}</div>
              </div>
            </div>
            <div class="fieldset">
              <div class="legend">{{ $t("EMAIL") }}</div>
              <div class="input textstyle" v-if="form.email">
                {{ form.email }}
              </div>
              <div class="input placeholder" v-else>{{ $t("EMAIL") }}</div>
            </div>
            <div class="fieldset">
              <div class="legend">{{ $t("PHONE_NUMBER") }}</div>
              <div class="input" v-if="form.phone">
                {{ form.phone.formated_phone }}
              </div>
              <div class="input placeholder" v-else>
                {{ $t("PHONE_NUMBER") }}
              </div>
            </div>
            <div class="fieldset">
              <div class="legend">{{ $t("SIREN_SIRET") }}</div>
              <div class="input" v-if="form.siret">{{ form.siret }}</div>
              <div class="input placeholder" v-else>
                {{ $t("SIREN_SIRET") }}
              </div>
            </div>
            <div class="fieldset">
              <div class="legend">{{ $t("NUM_TVA") }}</div>
              <div class="input" v-if="form.num_tva">{{ form.num_tva }}</div>
              <div class="input placeholder" v-else>{{ $t("NUM_TVA") }}</div>
            </div>

            <div class="fieldset" @click="openuser">
              <div class="legend">{{ $t("INTERLOCUTEUR") }}</div>
              <div class="input textstyle" v-if="devis.interlocuteur">
                {{ devis.interlocuteur.prenom }} {{ devis.interlocuteur.nom }}
              </div>
              <div class="input placeholder" v-else>
                {{ $t("INTERLOCUTEUR") }}
              </div>
            </div>
          </div>
          <b-modal id="entrepriseModal" ref="entrepriseModal" hide-footer>
            <template #modal-header="{ close }">
              <h5>{{ $t("CHOOSE") }} {{ $t("SOCIETY") }}</h5>
              <b-button size="sm" @click="close()">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.028"
                  height="17.028"
                  viewBox="0 0 17.028 17.028"
                >
                  <path
                    id="Icon_material-close"
                    data-name="Icon material-close"
                    d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                    transform="translate(-7.5 -7.5)"
                    fill="#393939"
                  />
                </svg>
              </b-button>
            </template>
            <form @submit.prevent="hideModal('entrepriseModal')">
              <div class="center">
                <b-form-group :label="$t('SOCIETY')">
                  <searchInput
                    :list="getAllentreprises"
                    :loader="getentrepriseLoading"
                    label="lib"
                    :searchFunc="all_entreprises"
                    @searchfilter="selectentreprise($event)"
                  ></searchInput>
                </b-form-group>
              </div>

              <div class="actionModel">
                <b-button variant="success" type="submit">
                  {{ $t("CONFIRM") }}
                </b-button>
              </div>
            </form>
          </b-modal>
          <div class="right">
            <b-form-group :label="$t('TYPE-FAC') + '*'">
              <b-form-select
                v-model="fac.type"
                :options="factypeoption"
                @change="verif($event)"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              :label="$t('TOT-TYP') + '*'"
              v-if="fac.type == 'facture.acompte'"
            >
              <b-form-select
                v-model="fac.total_type"
                :options="factotalType"
                @change="verif($event)"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              :label="$t('VALEUR') + '*'"
              v-if="fac.total_type && fac.type == 'facture.acompte'"
            >
              <b-form-input
                type="number"
                v-model="fac.valeur"
                placeholder="Écrire la valeur de total type"
                min="1"
                required
                @change="verif($event)"
              ></b-form-input>
            </b-form-group>
            <b-form-group :label="$t('START') + '*'">
              <b-form-input
                type="date"
                v-model="devis.debut_devis"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group :label="$t('END') + '*'">
              <b-form-input
                type="date"
                v-model="devis.fin_devis"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group :label="$t('DATE_VISITE_TECHNIQUE')">
              <b-form-input
                type="date"
                v-model="devis.visite_technique"
              ></b-form-input>
            </b-form-group>
            <div class="fieldset" @click="openuser" v-if="isAssistant">
              <div class="legend">{{ $t("USER") }}</div>
              <div class="input textstyle" v-if="devis.owner">
                {{ devis.owner.nom }} {{ devis.owner.prenom }}
              </div>
              <div class="input placeholder" v-else>
                {{ $t("USER") }}
              </div>
            </div>
            <hr />

            <div class="fieldset" @click="openclient">
              <div class="legend">{{ $t("CLIENT_NAME") + "*" }}</div>
              <div class="input textstyle" v-if="form2.nom">
                {{ form2.nom }} {{ form2.prenom }}
              </div>
              <div class="input placeholder" v-else>
                {{ $t("CLIENT_NAME") }}
              </div>
            </div>
            <div class="fieldset" @click="openclient">
              <div class="legend">{{ $t("CLIENT_ADRESS") }}</div>
              <div class="input textstyle" v-if="form2.rue">
                {{ form2.rue }}
              </div>
              <div class="input placeholder" v-else>
                {{ $t("CLIENT_ADRESS") }}
              </div>
            </div>
            <div class="justify" @click="openclient">
              <div class="fieldset">
                <div class="legend">{{ $t("POSTAL_CODE") }}</div>
                <div class="input" v-if="form2.cp">{{ form2.cp }}</div>
                <div class="input placeholder" v-else>
                  {{ $t("POSTAL_CODE") }}
                </div>
              </div>
              <div class="fieldset" @click="openclient">
                <div class="legend">{{ $t("CITY") }}</div>
                <div class="input textstyle" v-if="form2.ville">
                  {{ form2.ville }}
                </div>
                <div class="input placeholder" v-else>{{ $t("CITY") }}</div>
              </div>
            </div>
            <div class="fieldset" @click="openclient">
              <div class="legend">{{ $t("CLIENT_EMAIL") }}</div>
              <div class="input textstyle" v-if="form2.email">
                {{ form2.email }}
              </div>
              <div class="input" v-else-if="form2.email_u">
                {{ form2.email_u }}
              </div>
              <div class="input placeholder" v-else>
                {{ $t("CLIENT_EMAIL") }}
              </div>
            </div>
            <div class="fieldset" @click="openclient">
              <div class="legend">{{ $t("CLIENT_NUM") }}</div>
              <div class="input" v-if="form2.phone">
                {{ form2.phone.formated_phone }}
              </div>
              <div class="input placeholder" v-else>{{ $t("CLIENT_NUM") }}</div>
            </div>
            <div class="fieldset" @click="opendelegataire">
              <div class="legend">{{ $t("DELEGATAIRE") }}</div>
              <div class="input" v-if="devis.delegatairecopie">
                {{ devis.delegatairecopie.nom }}
              </div>
              <div class="input placeholder" v-else>
                {{ $t("DELEGATAIRE") }}
              </div>
            </div>
            <div class="fieldset">
              <div class="legend">{{ $t("DESC") }}</div>
              <b-form-textarea
                id="textarea-large"
                v-model="devis.description"
                size="lg"
                :placeholder="$t('DESC')"
                maxlength="1000"
              ></b-form-textarea>
            </div>
          </div>

          <b-modal id="delegataireModal" ref="delegataireModal" hide-footer>
            <template #modal-header="{ close }">
              <h5>{{ $t("DELEGATAIRE") }}</h5>
              <b-button size="sm" @click="close()">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.028"
                  height="17.028"
                  viewBox="0 0 17.028 17.028"
                >
                  <path
                    id="Icon_material-close"
                    data-name="Icon material-close"
                    d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                    transform="translate(-7.5 -7.5)"
                    fill="#393939"
                  />
                </svg>
              </b-button>
            </template>
            <form @submit.prevent="hideModal('delegataireModal')">
              <div class="center">
                <b-form-group :label="$t('DELEGATAIRE')">
                  <searchInput
                    :list="getAllDelegataire"
                    :loader="getDelegataireLoading"
                    label="nom"
                    :searchFunc="allDelegataire"
                    @searchfilter="selectdelegataire($event)"
                    :filtre="{
                      entreprise_id: form.id,
                    }"
                  ></searchInput>
                </b-form-group>
              </div>

              <div class="actionModel">
                <b-button variant="success" type="submit">
                  {{ $t("CONFIRM") }}
                </b-button>
              </div>
            </form>
          </b-modal>

          <b-modal id="clientModal" ref="clientModal" hide-footer>
            <template #modal-header="{ close }">
              <h5>{{ $t("CHOOSE") }} {{ $t("CUSTOMER") }}</h5>
              <b-button size="sm" @click="close()">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.028"
                  height="17.028"
                  viewBox="0 0 17.028 17.028"
                >
                  <path
                    id="Icon_material-close"
                    data-name="Icon material-close"
                    d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                    transform="translate(-7.5 -7.5)"
                    fill="#393939"
                  />
                </svg>
              </b-button>
            </template>
            <form @submit.prevent="hideModal('clientModal')">
              <div class="center">
                <b-form-group :label="$t('CUSTOMER')">
                  <searchInput
                    :list="getAllclients"
                    :loader="getclientLoading"
                    label="full_name"
                    :searchFunc="all_users"
                    @searchfilter="selectclient($event)"
                    modalename="client"
                    :filtre="{
                      entreprise: form.id,
                    }"
                  ></searchInput>
                </b-form-group>
              </div>

              <div class="actionModel">
                <b-button variant="success" type="submit">
                  {{ $t("CONFIRM") }}
                </b-button>
              </div>
            </form>
          </b-modal>

          <b-modal id="userModal" ref="userModal" hide-footer>
            <template #modal-header="{ close }">
              <h5>{{ $t("CHOOSE") }} {{ $t("USER") }}</h5>
              <b-button size="sm" @click="close()">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17.028"
                  height="17.028"
                  viewBox="0 0 17.028 17.028"
                >
                  <path
                    id="Icon_material-close"
                    data-name="Icon material-close"
                    d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                    transform="translate(-7.5 -7.5)"
                    fill="#393939"
                  />
                </svg>
              </b-button>
            </template>
            <form @submit.prevent="hideModal('userModal')">
              <div class="center">
                <b-form-group :label="$t('USER')">
                  <searchInput
                    :list="getAllUsers"
                    :loader="getOnlineUserLoading"
                    label="full_name"
                    :searchFunc="all_users"
                    @searchfilter="selectuser($event)"
                    :filtre="{
                      entreprise_id: form.id,
                    }"
                  ></searchInput>
                </b-form-group>
              </div>

              <div class="actionModel">
                <b-button variant="success" type="submit">
                  {{ $t("CONFIRM") }}
                </b-button>
              </div>
            </form>
          </b-modal>
        </div>
        <div class="groupe" v-for="(groupes, i) in devis.groupes" :key="i">
          <div class="piece">
            <div class="type">
              <p class="textstyle" v-if="group[i].room.lib">
                {{ group[i].room.lib }}
              </p>
              <p v-else>{{ $t("CHOOSE") }} {{ $t("PIECE_") + "*" }}</p>
              <b-button
                size="sm"
                @click="openpiece(i)"
                v-if="group[i].room.lib != 'Divers'"
              >
                <font-awesome-icon icon="pencil-alt" class="icons" />
              </b-button>
            </div>
            <div class="option">
              <b-button
                size="sm"
                v-if="devis.groupes.length > 1"
                @click="popGroup(i)"
              >
                <font-awesome-icon icon="trash" class="icons" />
              </b-button>
              <b-button
                size="sm"
                @click="closeGroup(i)"
                style="background: #e4261b"
              >
                <font-awesome-icon
                  icon="chevron-down"
                  class="icons"
                  v-if="!showGroup[i].bool"
                />
                <font-awesome-icon icon="chevron-up" class="icons" v-else />
              </b-button>
            </div>
          </div>
          <div class="corps" v-show="showGroup[i].bool">
            <div class="complement">
              <div @click="opentravaux(i)" class="block">
                <b-form-group :label="$t('WORKS') + '*'">
                  <div class="input textstyle" v-if="group[i].work">
                    {{ group[i].work.lib }}
                  </div>
                  <div class="input placeholder" v-else>{{ $t("WORKS") }}</div>
                </b-form-group>
              </div>

              <div
                @click="opensousTraitant(i)"
                class="block"
                v-if="group[i].work"
              >
                <b-form-group :label="$t('SOUS-TRAITANT')">
                  <div class="input textstyle" v-if="group[i].sousTraitant">
                    {{ group[i].sousTraitant.name }}
                  </div>
                  <div class="input placeholder" v-else>
                    {{ $t("SOUS-TRAITANT") }}
                  </div>
                </b-form-group>
              </div>
            </div>
            <div class="table">
              <table>
                <thead>
                  <tr>
                    <td
                      style="background-color: #fff; min-width: 32px"
                      v-if="group[i].room.lib != 'Divers'"
                    ></td>
                    <td
                      style="background-color: #fff; min-width: 32px"
                      v-else
                    ></td>
                    <td style="border-radius: 5px 0px 0px 0px ,width:12%">
                      {{ $t("REF") }}
                    </td>
                    <td style="width: 44%">
                      {{ $t("WORDING") }}/{{ $t("DESC") }}
                    </td>
                    <td style="width: 7%">{{ $t("QTE") }}</td>
                    <td style="width: 9%">{{ $t("UNITEU") }}</td>
                    <td style="width: 9%">{{ $t("PUHT") }}</td>
                    <td class="mintaille">{{ $t("TOTALHT") }}</td>
                    <td class="mintaille">{{ $t("REMISE") }}</td>
                    <td class="mintaille">
                      {{ $t("Avec Remise") }}
                    </td>
                    <td
                      class="mintaille"
                      style="border-radius: 0px 5px 0px 0px"
                    >
                      {{ $t("VAT") }}
                    </td>
                    <td
                      class="action_head"
                      style="display: none; min-width: 32px"
                    ></td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, j) in groupes.produits" :key="j">
                    <td
                      style="min-width: 32px"
                      v-if="group[i].room.lib != 'Divers'"
                    ></td>
                    <td style="min-width: 32px" v-else></td>
                    <td>
                      <b-form-input v-model="item.ref" maxlength="10">{{
                        item.ref
                      }}</b-form-input>
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.lib"
                        oninput="this.value = this.value.toUpperCase()"
                        required
                        maxlength="100"
                      ></b-form-input>
                      <b-form-input
                        v-model="item.desc"
                        maxlength="1000"
                      ></b-form-input>
                    </td>
                    <td>
                      <b-form-input
                        type="number"
                        min="1"
                        @change="updateTTC()"
                        v-model="item.quantite"
                        max="10000000"
                        required
                        @input="controlsize(item.quantite, 8)"
                      ></b-form-input>
                    </td>
                    <td>
                      <b-form-select
                        v-model="item.type_unite"
                        :options="optionstype"
                        text-field="text"
                        value-field="value"
                        required
                      ></b-form-select>
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.unit_price"
                        type="number"
                        step="1"
                        min="0"
                        max="10000000.00"
                        required
                        @input="controlsize(item.unit_price, 8)"
                      ></b-form-input>
                    </td>
                    <td>
                      <b-form-input
                        :value="totalHT(item)"
                        readonly
                      ></b-form-input>
                    </td>
                    <td>
                      <b-form-input
                        v-model="item.remise_value"
                        type="number"
                        step="any"
                        min="0"
                        max="100000"
                        @keyup="recalculerTotal"
                        @change="updateTTC()"
                        :readonly="remise == 'remise_globale'"
                      ></b-form-input>
                      <b-form-select
                        v-model="item.remise_type"
                        :options="optionsRemiseType"
                        :disabled="remise == 'remise_globale'"
                      ></b-form-select>
                    </td>
                    <td>
                      <b-form-input
                        :value="totalAR(item)"
                        readonly
                      ></b-form-input>
                    </td>
                    <td>
                      <b-form-select
                        v-model="item.tva"
                        :options="optionsTVA"
                        required
                      ></b-form-select>
                    </td>
                    <td
                      class="action_col"
                      style="min-width: 28px; max-width: 28px; margin-top: 6px"
                    >
                      <font-awesome-icon
                        icon="times"
                        @click="popProduit(i, j)"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      v-if="group[i].room.lib != 'Divers'"
                      style="
                        min-width: 32px;
                        max-width: 32px;
                        padding: 0 !important;
                      "
                    >
                      <div
                        class="add"
                        @click="openproduit(i)"
                        :title="$t('ADD') + ' ' + $t('PRODUCT')"
                      >
                        <font-awesome-icon icon="plus" class="icons" />
                      </div>
                    </td>
                    <td
                      style="
                        min-width: 32px;
                        max-width: 32px;
                        padding: 0 !important;
                      "
                      v-else
                    >
                      <div
                        class="add"
                        :title="$t('JOKER')"
                        @click="addJokerProduct(i)"
                      >
                        <font-awesome-icon icon="plus" class="icons" />
                      </div>
                    </td>
                    <td>
                      <div class="detail_pvw"></div>
                    </td>
                    <td>
                      <div class="detail_pvw"></div>
                    </td>
                    <td>
                      <div class="detail_pvw"></div>
                    </td>
                    <td>
                      <div class="detail_pvw"></div>
                    </td>
                    <td>
                      <div class="detail_pvw"></div>
                    </td>
                    <td>
                      <div class="detail_pvw"></div>
                    </td>
                    <td>
                      <div class="detail_pvw"></div>
                    </td>
                    <td>
                      <div class="detail_pvw"></div>
                    </td>
                    <td>
                      <div class="detail_pvw"></div>
                    </td>
                    <td
                      style="min-width: 28px; max-width: 28px"
                      v-if="devis.groupes[i].produits.length"
                    ></td>
                  </tr>
                </tbody>
              </table>

              <b-modal id="produitModal" ref="produitModal" hide-footer>
                <template #modal-header="{ close }">
                  <h5>{{ $t("CHOOSE") }}</h5>

                  <b-button size="sm" @click="close()">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17.028"
                      height="17.028"
                      viewBox="0 0 17.028 17.028"
                    >
                      <path
                        id="Icon_material-close"
                        data-name="Icon material-close"
                        d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                        transform="translate(-7.5 -7.5)"
                        fill="#393939"
                      />
                    </svg>
                  </b-button>
                </template>
                <form @submit.prevent="hideModal('produitModal')">
                  <b-tabs content-class="tabs" justified v-model="tabIndex">
                    <b-tab title="First" active lazy @click="loadProduit">
                      <template #title>
                        <b-spinner
                          type="border"
                          small
                          v-if="getProductLoading"
                        ></b-spinner>
                        {{ $t("TypePrestation.Fourniture") }}
                      </template>
                      <div class="center">
                        <searchInput
                          :list="getAllProducts"
                          :loader="getProductLoading"
                          label="lib"
                          :searchFunc="all_products"
                          @searchfilter="addProduit($event, 'produit')"
                          modalename="article"
                          :filtre="{
                            entreprise: form.id,
                          }"
                        ></searchInput>
                      </div>
                    </b-tab>
                    <b-tab title="Second" lazy @click="loadForfait">
                      <template #title>
                        <b-spinner
                          type="border"
                          small
                          v-if="getforfaitLoading"
                        ></b-spinner>
                        {{ $t("TypePrestation.Forfait") }}
                      </template>
                      <div class="center">
                        <searchInput
                          :list="getAllforfaits"
                          :loader="getforfaitLoading"
                          label="lib"
                          :searchFunc="all_forfaits"
                          :filtre="{
                            entreprise: form.id,
                          }"
                          @searchfilter="addProduit($event, 'forfait')"
                          modalename="Forfait"
                        ></searchInput>
                      </div>
                    </b-tab>
                    <b-tab title="Third" lazy @click="loadMainOeuvre">
                      <template #title>
                        <b-spinner
                          type="border"
                          small
                          v-if="getmainoeuvreLoading"
                        ></b-spinner>
                        {{ $t("TypePrestation.MainOeuvre") }}
                      </template>
                      <div class="center">
                        <searchInput
                          :list="getAllmainoeuvres"
                          :loader="getmainoeuvreLoading"
                          label="lib"
                          :searchFunc="all_mainoeuvres"
                          :filtre="{
                            entreprise: form.id,
                          }"
                          @searchfilter="addProduit($event, 'mainoeuvre')"
                          modalename="MainOeuvre"
                        ></searchInput>
                      </div>
                    </b-tab>
                  </b-tabs>

                  <div class="actionModel">
                    <b-button variant="success" @click="pushProduits(i)">
                      {{ $t("CONFIRM") }}
                    </b-button>
                  </div>
                </form>
              </b-modal>
            </div>
            <p class="sousTraitant" v-if="group[i].sousTraitant">
              <span @click="cleanSousTraitant">
                <b-icon-x-circle></b-icon-x-circle>
              </span>
              Sous-traitant: {{ group[i].sousTraitant.name }}, SIRET:
              {{ group[i].sousTraitant.num_siret }}, Num RGE:
              {{ group[i].sousTraitant.num_rge }}
            </p>
          </div>
        </div>

        <b-modal id="travauxModal" ref="travauxModal" hide-footer>
          <template #modal-header="{ close }">
            <h5>{{ $t("CHOOSE") }} {{ $t("WORK") }}</h5>
            <b-button size="sm" @click="close()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.028"
                height="17.028"
                viewBox="0 0 17.028 17.028"
              >
                <path
                  id="Icon_material-close"
                  data-name="Icon material-close"
                  d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                  transform="translate(-7.5 -7.5)"
                  fill="#393939"
                />
              </svg>
            </b-button>
          </template>
          <form @submit.prevent="hideModal('travauxModal')">
            <div class="center">
              <b-form-group>
                <searchInput
                  :list="getAlltravaux"
                  :loader="getLoadingtravaux"
                  label="lib"
                  :searchFunc="alltravaux"
                  @searchfilter="selecttravaux($event)"
                  modalename="traveaux"
                  :filtre="{ entreprise: form.id }"
                  :with_default="1"
                  :with_default_lib="$t('Afficher les travaux publiques')"
                ></searchInput>
              </b-form-group>
            </div>

            <div class="actionModel">
              <b-button variant="success" type="submit">
                {{ $t("CONFIRM") }}
              </b-button>
            </div>
          </form>
        </b-modal>

        <b-modal id="pieceModal" ref="pieceModal" hide-footer>
          <template #modal-header="{ close }">
            <h5>{{ $t("CHOOSE") }} {{ $t("ROOM") }}</h5>
            <b-button size="sm" @click="close()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.028"
                height="17.028"
                viewBox="0 0 17.028 17.028"
              >
                <path
                  id="Icon_material-close"
                  data-name="Icon material-close"
                  d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                  transform="translate(-7.5 -7.5)"
                  fill="#393939"
                />
              </svg>
            </b-button>
          </template>
          <form @submit.prevent="hideModal('pieceModal')">
            <div class="center">
              <b-form-group>
                <searchInput
                  :list="getAllpiece"
                  :loader="getLoadingpiece"
                  label="lib"
                  :searchFunc="allpiece"
                  @searchfilter="selectpiece($event)"
                  modalename="piece"
                  :filtre="{ entreprise: form.id }"
                  :with_default="1"
                  :with_default_lib="$t('Afficher les pieces publiques')"
                ></searchInput>
              </b-form-group>
            </div>

            <div class="actionModel">
              <b-button variant="success" type="submit">
                {{ $t("CONFIRM") }}
              </b-button>
            </div>
          </form>
        </b-modal>

        <b-modal id="sousTraitantModal" ref="sousTraitantModal" hide-footer>
          <template #modal-header="{ close }">
            <h5>{{ $t("CHOOSE") }} {{ $t("SOUS-TRAITANT") }}</h5>
            <b-button size="sm" @click="close()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.028"
                height="17.028"
                viewBox="0 0 17.028 17.028"
              >
                <path
                  id="Icon_material-close"
                  data-name="Icon material-close"
                  d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                  transform="translate(-7.5 -7.5)"
                  fill="#393939"
                />
              </svg>
            </b-button>
          </template>
          <form @submit.prevent="hideModal('sousTraitantModal')">
            <div class="center">
              <b-form-group>
                <searchInput
                  :list="getAllsousTraitant"
                  :loader="getLoadingsousTraitant"
                  label="name"
                  :searchFunc="allsousTraitant"
                  @searchfilter="selectSousTraitant($event)"
                  :filtre="{ entreprise: form.id }"
                  :with_default="0"
                >
                </searchInput>
              </b-form-group>
            </div>

            <div class="actionModel">
              <b-button variant="success" type="submit">
                {{ $t("CONFIRM") }}
              </b-button>
            </div>
          </form>
        </b-modal>

        <div
          class="groupe_pvw"
          @click="addpiece()"
          :title="$t('INTERVENTION')"
          v-if="
            (devis.groupes[devis.groupes.length - 1].type_piece_id &&
              devis.groupes[devis.groupes.length - 1].type_traveaux_id &&
              devis.groupes[devis.groupes.length - 1].produits.length > 0) ||
            group[0].room.lib == 'Divers'
          "
        >
          <font-awesome-icon icon="plus" class="mr-2" />
          {{ $t("INTERVENTION") }}
        </div>

        <div
          class="groupe_pvw"
          @click="addJoker()"
          :title="$t('JOKER')"
          v-if="!DiverExist"
        >
          <font-awesome-icon icon="plus" class="mr-2" />
          <div style="margin-top: 2px">{{ $t("JOKER") }}</div>
        </div>

        <div class="form" style="border: none">
          <div class="left">
            <div class="fieldset textarea">
              <div class="legend">{{ $t("REMARQUE") }}</div>
              <b-form-textarea
                id="textarea-large"
                v-model="devis.remarque"
                size="lg"
                :placeholder="$t('REMARQUE')"
                maxlength="1000"
              ></b-form-textarea>
            </div>
            <div class="fieldset textarea">
              <div class="legend">{{ $t("REMARQUE") }}</div>
              <b-form-textarea
                id="textarea-large"
                v-model="devis.remarque"
                size="lg"
                placeholder="Remarque"
                maxlength="1000"
              ></b-form-textarea>
            </div>
            <div class="fieldset">
              <div class="legend">{{ $t("Condition de paiement") }}</div>
              <b-form-textarea
                v-model="devis.condition_paiment"
                size="lg"
                placeholder="Conditions Reglements"
                maxlength="190"
              ></b-form-textarea>
            </div>
            <div class="fieldset">
              <div class="legend">{{ $t("MOD_PAYMENT") }}</div>
              <b-form-select
                v-model="devis.moyens_paiement"
                :options="optionspaiement"
                text-field="text"
                value-field="value"
              ></b-form-select>
            </div>
            <div class="fieldset" v-if="devis.moyens_paiement == 'financement'">
              <div class="legend">{{ $t("ORG_PRETEUR") }}</div>
              <b-form-input
                v-model="devis.organisme_preteur"
                type="text"
                :placeholder="$t('ORG_PRETEUR')"
                class="input placeholder"
                maxlength="100"
              ></b-form-input>
            </div>
            <div class="fieldset" v-if="devis.moyens_paiement == 'financement'">
              <div class="legend">{{ $t("MONTANT_CREDIT") }}</div>
              <b-form-input
                v-model="devis.montant_credit"
                type="number"
                min="0"
                max="10000000"
                :placeholder="$t('MONTANT_CREDIT')"
                class="input placeholder"
                @input="controlsize(devis.montant_credit, 8)"
              ></b-form-input>
            </div>
            <div class="fieldset" v-if="devis.moyens_paiement == 'financement'">
              <div class="legend">{{ $t("TEAUX_NOMINAL") }}</div>
              <b-form-input
                v-model="devis.taux_nominal"
                type="number"
                min="0"
                max="10000000"
                :placeholder="$t('TEAUX_NOMINAL')"
                class="input placeholder"
                @input="controlsize(devis.taux_nominal, 8)"
              ></b-form-input>
            </div>
            <div class="fieldset" v-if="devis.moyens_paiement == 'financement'">
              <div class="legend">{{ $t("MENSUALITE") }}</div>
              <b-form-input
                v-model="devis.mensualite"
                type="number"
                min="0"
                :placeholder="$t('MENSUALITE')"
                class="input placeholder"
                max="10000000"
                @input="controlsize(devis.mensualite, 8)"
              ></b-form-input>
            </div>
            <div class="fieldset" v-if="devis.moyens_paiement == 'financement'">
              <div class="legend">{{ $t("NBR_MENSUALITE") }}</div>
              <b-form-input
                v-model="devis.nombre_mensualités"
                type="number"
                min="0"
                :placeholder="$t('NBR_MENSUALITE')"
                class="input placeholder"
                max="10000000"
                @input="controlsize(devis.nombre_mensualités, 8)"
              ></b-form-input>
            </div>
            <div class="fieldset" v-if="devis.moyens_paiement == 'financement'">
              <div class="legend">{{ $t("TAEG") }}</div>
              <b-form-input
                v-model="devis.taeg"
                type="number"
                min="0"
                :placeholder="$t('TAEG')"
                class="input placeholder"
                max="10000000"
                @input="controlsize(devis.taeg, 8)"
              ></b-form-input>
            </div>
            <div class="fieldset" v-if="devis.moyens_paiement == 'financement'">
              <div class="legend">{{ $t("CRIDIT_TOTALE") }}</div>
              <b-form-input
                v-model="devis.cout_total_credit"
                type="number"
                min="0"
                :placeholder="$t('CRIDIT_TOTALE')"
                class="input placeholder"
                max="10000000"
                @input="controlsize(devis.cout_total_credit, 8)"
              ></b-form-input>
            </div>

            <div class="fieldset" v-if="devis.moyens_paiement == 'financement'">
              <div class="legend">{{ $t("ASSURANCE_TYPE") }}</div>
              <div class="radio">
                <b-form-radio
                  v-model="devis.assureance"
                  name="some-radios"
                  value="Avec assurance"
                  >{{ $t("AVECASSURANCE") }}</b-form-radio
                >
                <b-form-radio
                  v-model="devis.assureance"
                  name="some-radios"
                  value="Sans assurance"
                  >{{ $t("SANSASSURANCE") }}</b-form-radio
                >
              </div>
            </div>
            <div class="fieldset" v-if="devis.assureance == 'Avec assurance'">
              <div class="legend">{{ $t("TYPE_ASSURANCE") }}</div>
              <b-form-select
                v-model="devis.assurance_type"
                :options="optiontypeassurance"
                text-field="text"
                value-field="value"
              ></b-form-select>
            </div>
          </div>
          <div class="right">
            <div class="subtable">
              <table class="complet">
                <tr>
                  <td class="thead">
                    {{ $t("TOTAL_HT") }}
                  </td>
                  <td style="border-radius: 0px 0px 0px 0px">
                    {{ prixHT() | financial }}
                  </td>
                </tr>
                <tr>
                  <td class="thead">
                    <b-form-select
                      class="select"
                      v-model="remise"
                      :options="optionsRemise"
                      @change="typeRemise"
                    ></b-form-select>
                  </td>
                  <td v-if="remise == 'remise'">
                    {{ devis.remise_value | financial }}
                  </td>
                  <td v-else>
                    <div class="remise_block">
                      <b-form-input
                        v-model="devis.remise_value"
                        type="number"
                        class="input"
                        max="100000"
                        step="any"
                        @keyup="recalculerTotal"
                        @change="updateTTC()"
                      ></b-form-input>
                      <b-form-select
                        v-model="devis.remise_type"
                        :options="optionsRemiseType"
                        required
                        @change="typeRemise"
                      ></b-form-select>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="thead">
                    {{ $t("TOTALREMISE") }}
                  </td>
                  <td>
                    {{ prixAR() | financial }}
                  </td>
                </tr>
                <template v-if="TVA.length">
                  <tr v-for="tva in TVA" :key="'tva' + tva.tva">
                    <td class="thead">TVA ({{ tva.tva }} % )</td>
                    <td>{{ tva.value | financial }}</td>
                  </tr>
                </template>
                <tr>
                  <td class="thead">
                    {{ $t("Total TTC") }}
                  </td>
                  <td>{{ prixTTC() | financial }}</td>
                </tr>
                <tr v-if="fac.type == 'facture.acompte'">
                  <td class="thead">
                    {{ $t("ACCOMPT") }}
                  </td>
                  <td>{{ Accompt | financial }}</td>
                </tr>
                <tr v-if="fac.type == 'facture.acompte'">
                  <td class="thead" style="border-radius: 0px 0px 0px 5px">
                    {{ $t("RESTE") }}
                  </td>
                  <td style="border-radius: 0px 0px 5px 0px">{{ Reste }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <b-modal
        id="part2facture"
        ref="part2facture"
        content-class="shadow"
        title="Help"
        modal-footer
        size="lg"
      >
        <template>
          <div class="center">
            <b-form-group :label="$t('QUESTION_AIDE')">
              <b-form-select
                v-model="devis.with_aide"
                :options="option_with_aide"
                text-field="text"
                value-field="value"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              :label="$t('MA_PRIME_RENOV')"
              v-if="devis.with_aide == true"
            >
              <b-form-input
                v-model="devis.montant_maprimrenov"
                type="number"
                min="0"
                max="10000000"
                :placeholder="$t('NBR_MENSUALITE')"
                @input="controlsize(devis.montant_maprimrenov, 8)"
                :required="!devis.montant_cee"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              :label="$t('MONTANT_CEE')"
              v-if="devis.with_aide == true"
            >
              <b-form-input
                v-model="devis.montant_cee"
                type="number"
                min="0"
                max="1000000"
                :placeholder="$t('MONTANT_CEE')"
                class="input placeholder"
                @input="controlsize(devis.montant_cee, 8)"
                :required="!devis.montant_maprimrenov"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              :label="$t('Barème MPR')"
              v-if="devis.with_aide == true"
            >
              <b-form-select
                v-model="devis.bareme_mpr"
                :options="Barememproption"
                text-field="text"
                value-field="value"
                :required="+devis.montant_maprimrenov > 0"
              ></b-form-select>
            </b-form-group>
          </div>
        </template>

        <template #modal-footer="{ close }">
          <div class="double">
            <b-button variant="danger" @click="close">
              <div class="block-spinner">
                {{ $t("CANCEL") }}
              </div>
            </b-button>
            <b-button variant="success" @click="reponsequestion">
              <div class="block-spinner">
                {{ $t("CONFIRM") }}
              </div>
            </b-button>
          </div>
        </template>
      </b-modal>
      <div class="d-flex justify-content-around mt-5 submit">
        <b-button @click="openmodelpart()" :title="$t('INVOICE_GENERATE')">
          {{ $t("INVOICE_GENERATE") }}
        </b-button>
      </div>
    </form>
    <CommentMarche to="/how-it-work/facture"></CommentMarche>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { domains } from "@/environment";
import axios from "axios";
import searchInput from "@/components/ui/searchInput";
import formatMoney from "accounting-js/lib/formatMoney.js";
import { useVuelidate } from "@vuelidate/core";
import { maxValue } from "@vuelidate/validators";
import CommentMarche from "../components/commentMarche.vue";

export default {
  setup() {
    return { v$: useVuelidate({ $lazy: true, $autoDirty: true }) };
  },
  components: {
    searchInput,
    CommentMarche,
  },
  data() {
    return {
      isOwner: true,
      page: 1,
      per_page: 1000,
      downloadUrl: domains.download,
      search: "",
      form: {},
      form2: {
        nom: null,
        prenom: null,
      },
      factypeoption: [
        { value: "facture.acompte", text: this.$t("FACT-ACOMPTE") },
        { value: "facture.fin-travaux", text: this.$t("Fin travaux") },
      ],
      factotalType: [
        { value: "pourcentage", text: "Acompte Pourcentage" },
        { value: "ttc", text: "Acompte ttc" },
      ],
      optionstype: [
        { value: "m2", text: this.$t("m2") },
        { value: "ml", text: this.$t("ML") },
        { value: "h", text: this.$t("h") },
        { value: "forfait", text: this.$t("FORFAIT") },
        { value: "unitaire", text: this.$t("unitaire") },
      ],
      optionsTVA: [
        { value: 0, text: "0%" },
        { value: 5.5, text: "5.5%" },
        { value: 10, text: "10%" },
        { value: 20, text: "20%" },
      ],

      currentGroup: {
        produit_id: null,
        type_piece_id: null,
        piece: null,
        type_traveaux_id: null,
        travaux: null,
        type_piece: null,
        type_traveaux: null,
        produits: [],
        produit: {
          lib: null,
          unit_price: null,
          img: null,
          quantite: 1,
          desc: null,
          type_ligne: null,
        },
      },

      devis: {
        remise: 0,
        remise_type: "remise.HT",
        interlocuteur: null,
        owner: null,
        lang: "fr",
        client_id: null,
        modele_id: 14,
        debut_devis: null,
        fin_devis: null,
        visite_technique: null,
        condition_paiment: null,
        description: null,
        moyens_paiement: "cheque_virement_espece",
        organisme_preteur: null,
        montant_credit: null,
        taux_nominal: null,
        mensualite: null,
        nombre_mensualités: null,
        taeg: null,
        cout_total_credit: null,
        assureance: "Sans assurance",
        assurance_type: null,
        remarque: null,
        delegatairecopie: null,
        with_aide: null,
        montant_maprimrenov: null,
        montant_cee: null,
        bareme_mpr: null,
        groupes: [
          {
            produits: [],
          },
        ],
      },
      groupes: [
        {
          produits: [],
        },
      ],
      group: [
        {
          room: {
            lib: null,
          },
          work: null,
        },
      ],
      remise: "remise",
      fac: {
        type: "facture.fin-travaux",
        total_type: null,
        valeur: null,
      },
      pdfInfo: null,
      save: 0,
      messages: "Veuillez remplir tout les champs correctement !",
      showDismissibleAlert: 0,
      showGroup: [
        {
          bool: true,
        },
      ],
      optiontypeassurance: [
        { value: "deces_invalidite", text: this.$t("DECES_INVALIDITE") },
        { value: "perte_emploi", text: this.$t("PERTE_EMPLOI") },
        {
          value: "deces_invalidite_perte_emploi",
          text: this.$t("DECES_INVALIDITE_PERTE_EMPLOI"),
        },
      ],
      optionspaiement: [
        {
          value: "cheque_virement_espece",
          text: this.$t("Cheque_virement_espece"),
        },
        { value: "financement", text: this.$t("FINANCEMENT") },
      ],
      Barememproption: [
        { value: null, text: "Aucun" },
        { value: "blue", text: this.$t("BLEU") },
        { value: "yellow", text: this.$t("YELLOW") },
        { value: "purple", text: this.$t("PURPLE") },
        { value: "pink", text: this.$t("PINK") },
      ],
      option_with_aide: [
        { value: true, text: this.$t("Oui") },
        { value: false, text: this.$t("Non") },
      ],
      optionsRemiseType: [
        { value: "remise.HT", text: "€" },
        { value: "remise.pourcentage", text: "%" },
      ],
      optionsRemise: [
        { value: "remise", text: this.$t("REMISE_LIGNE") },
        { value: "remise_globale", text: this.$t("REMISE_GLOBALE") },
      ],
      newclient: {
        nom: null,
        type: null,
        prenom: null,
        email: null,
        rue: null,
        ville: null,
        cp: null,
        tel: null,
      },
      resultsExample: {
        tel: null,
        valid: false,
      },
      numberError: false,
      quit: true,
      tabIndex: 0,
    };
  },
  validations() {
    return {
      devis: {
        montant_credit: { maxValueValue: maxValue(10000) },
        // taux_nominal: { maxLength: maxLength(50) },
        // moyens_paiement: { maxLength: maxLength(50) },
        // mensualite: { maxLength: maxLength(50) },
        // nombre_mensualités: { maxLength: maxLength(50) },
        // taeg: { maxLength: maxLength(50) },
        // cout_total_credit: { maxLength: maxLength(50) },
        // montant_maprimrenov: { maxLength: maxLength(50) },
        // montant_cee: { maxLength: maxLength(50) },
      },
    };
  },
  methods: {
    ...mapActions([
      "all_forfaits",
      "all_mainoeuvres",
      "all_products",
      "allpiece",
      "alltravaux",
      "entreprise",
      "all_clients",
      "store_facture",
      "all_entreprises",
      "all_users",
      "pays",
      "allDelegataire",
      "client",
      "entreprise",
      "allsousTraitant",
    ]),

    openmodelpart() {
      var valid = true;
      var nbgroup = this.devis.groupes.length;
      for (let i = 0; i < nbgroup; i++) {
        if (this.devis.groupes[i].produits.length == 0) {
          this.messages = "Veuillez ajouter un article";
          this.showDismissibleAlert = true;
          valid = false;
          break;
        }
      }
      if (!this.form2.id) {
        this.messages = "Veuillez ajouter un client";
        this.showDismissibleAlert = true;
        valid = false;
      }
      if (!this.fac.type) {
        this.messages = "Veuillez choisir le type de facture";
        this.showDismissibleAlert = true;
        valid = false;
      }
      if (this.fac.type == "facture.acompte") {
        if (!this.fac.total_type) {
          this.messages = "Veuillez choisir le totale type de facture";
          this.showDismissibleAlert = true;
          valid = false;
        } else if (!this.fac.valeur) {
          this.messages = "Veuillez choisir le valeur de total type choisi ";
          this.showDismissibleAlert = true;
          valid = false;
        }
      }
      if (valid) {
        this.$refs["part2facture"].show();
      }
    },
    reponsequestion() {
      this.hideModal("part2facture");
      this.facture();
    },
    hideModal(ref) {
      this.$refs[ref].hide();
    },
    selectuser(user) {
      this.hideModal("userModal");
      this.quit = false;
      if (this.isOwner) {
        this.devis.owner = { ...user };
      } else {
        this.devis.interlocuteur = { ...user };
      }
    },
    verif(event) {
      if (event) {
        this.showDismissibleAlert = false;
      }
    },
    selectdelegataire(delegataire) {
      this.hideModal("delegataireModal");
      this.quit = false;
      this.devis.delegatairecopie = { ...delegataire };
    },
    openclient() {
      const data = {
        entreprise_id: this.form
          ? this.form.id
          : this.getOnlineUser.entreprise.id,
        page: this.page,
        per_page: this.per_page,
      };
      if (this.search) {
        data.search = this.search;
      }
      this.all_clients(data).then(() => {
        this.$refs["clientModal"].show();
      });
    },

    selectclient(client) {
      this.hideModal("clientModal");
      this.showDismissibleAlert = false;
      this.quit = false;
      this.form2 = { ...client };
    },

    resetclient() {
      this.form2 = null;
      this.form2 = {
        nom: null,
      };
    },

    resetdevis() {
      this.devis = {
        remise: 0,
        owner: null,
        lang: "fr",
        client_id: null,
        modele_id: 14,
        debut_devis: null,
        fin_devis: null,
        groupes: [
          {
            produits: [],
          },
        ],
      };
      this.groupes = [
        {
          produits: [],
        },
      ];

      this.group = [
        {
          room: null,
          work: null,
        },
      ];
    },

    selectentreprise(ent) {
      this.hideModal("entrepriseModal");
      this.quit = false;
      this.form = { ...ent };
      if (this.form.id != ent.id) {
        this.resetclient();
      }
    },
    openuser(isOwner = true) {
      this.all_users({
        entreprise_id: this.form
          ? this.form.id
          : this.getOnlineUser.entreprise.id,
        page: this.page,
        per_page: this.per_page,
      }).then(() => {
        this.isOwner = !isOwner;
        this.$refs["userModal"].show();
      });
    },
    opendelegataire() {
      this.allDelegataire({
        page: this.page,
        per_page: this.per_page,
        entreprise_id: this.form.id,
      }).then(() => {
        this.$refs["delegataireModal"].show();
      });
    },

    openpiece(i) {
      this.allpiece({
        entreprise: this.form.id
          ? this.form.id
          : this.getOnlineUser.entreprise.id,
        page: this.page,
        per_page: this.per_page,
      }).then(() => {
        this.saveGroup(i);
        this.$refs["pieceModal"].show();
      });
    },

    opentravaux(i) {
      const param = {
        entreprise: this.form.id
          ? this.form.id
          : this.getOnlineUser.entreprise.id,
        page: this.page,
        per_page: this.per_page,
      };
      this.alltravaux(param).then(() => {
        this.saveGroup(i);
        this.$refs["travauxModal"].show();
      });
    },

    opensousTraitant(i) {
      this.allsousTraitant({
        entreprise: this.form.id
          ? this.form.id
          : this.getOnlineUser.entreprise.id,
        page: this.page,
        per_page: this.perpageselect,
      }).then(() => {
        this.saveGroup(i);
        this.$refs["sousTraitantModal"].show();
      });
    },

    addJokerProduct(i) {
      var produit = {
        lib: null,
        desc: "",
        ref: "",
        tva: 0,
        remise_value: 0,
        remise_type: "remise.HT",
        quantite: 1,
        unit_price: null,
        type_unite: "unitaire",
        type_ligne: "joker",
      };

      var find = this.devis.groupes[i].produits.findIndex(
        (item) => item.type_ligne == "joker" && (!item.lib || !item.unit_price)
      );

      if (find < 0) this.devis.groupes[i].produits.push(produit);
      else {
        alert("completez correctement le produit precedant (lib, unit_price)");
      }
    },

    jokerBool(i) {
      this.devis.groupes[i].produits.forEach((product) => {
        if (product.type_ligne == "joker") {
          if (!product.lib) return false;
        }
      });

      return true;
    },

    loadProduit() {
      const data = {
        page: this.page,
        per_page: this.perpageselect,
        entreprise: this.form.id
          ? this.form.id
          : this.getOnlineUser.entreprise.id,
      };
      this.all_products(data);
    },

    loadForfait() {
      const data = {
        page: this.page,
        per_page: this.perpageselect,
        entreprise: this.form.id
          ? this.form.id
          : this.getOnlineUser.entreprise.id,
      };
      this.all_forfaits(data);
    },

    loadMainOeuvre() {
      const data = {
        page: this.page,
        per_page: this.perpageselect,
        entreprise: this.form.id
          ? this.form.id
          : this.getOnlineUser.entreprise.id,
      };
      this.all_mainoeuvres(data);
    },

    openproduit(i) {
      const data = {
        page: this.page,
        per_page: this.perpageselect,
        entreprise: this.form.id
          ? this.form.id
          : this.getOnlineUser.entreprise.id,
      };
      this.saveGroup(i);
      this.$refs["produitModal"][i].show();
      this.all_products(data);
    },

    selectpiece(piece) {
      this.hideModal("pieceModal");
      this.quit = false;
      this.group[this.save].room = piece;
      this.devis.groupes[this.save].type_piece_id = piece.id;
      this.devis.groupes[this.save].piece = piece.lib;
    },

    selecttravaux(travaux) {
      this.hideModal("travauxModal");
      this.quit = false;
      this.group[this.save].work = travaux;
      this.devis.groupes[this.save].type_traveaux_id = travaux.id;
      this.devis.groupes[this.save].travaux = travaux.lib;
    },

    selectSousTraitant(sousTraitant) {
      this.hideModal("sousTraitantModal");
      this.quit = false;
      this.group[this.save].sousTraitant = sousTraitant;
      this.devis.groupes[this.save].sous_traitant_id = sousTraitant.id;
      this.devis.groupes[this.save].sousTraitant = sousTraitant.name;
      this.$forceUpdate();
    },

    cleanSousTraitant() {
      this.group[this.save].sousTraitant = null;
      this.$forceUpdate();
    },

    totalHT(item) {
      const totalSansRemise = item.unit_price * item.quantite;
      if (Math.round(totalSansRemise * 100) / 100 < 0) return 0;
      return Math.round(totalSansRemise * 100) / 100;
    },

    totalAR(item) {
      const totalSansRemise = this.totalHT(item);
      var totalAvecRemise = totalSansRemise;
      if (item.remise_type == "remise.HT")
        totalAvecRemise -= item.remise_value ? +item.remise_value : 0;
      else totalAvecRemise -= (item.remise_value * totalSansRemise) / 100;

      if (totalAvecRemise < 0) return 0;
      return Math.round(totalAvecRemise * 100) / 100;
    },

    recalculerTotal() {
      this.$forceUpdate();
    },

    prixHT() {
      var ht = 0;
      this.devis.groupes.forEach((groupe) => {
        groupe.produits.forEach((product) => {
          ht += this.totalHT(product);
        });
      });
      return Math.round(ht * 100) / 100;
    },

    prixAR() {
      var ht = 0;

      this.devis.groupes.forEach((groupe) => {
        groupe.produits.forEach((product) => {
          ht += this.totalAR(product);
        });
      });
      if (this.remise != "remise") {
        if (this.devis.remise_type == "remise.HT")
          ht -= this.devis.remise_value;
        else ht -= (this.devis.remise_value * ht) / 100;
      }
      if (ht < 0) return 0;

      return Math.round(ht * 100) / 100;
    },

    prixTTC() {
      let tva = 0;
      let totalRemise = 0;
      this.devis.groupes.forEach((groupe) => {
        groupe.produits.forEach((product) => {
          let ht = this.totalHT(product);
          if (product.remise_type == "remise.HT")
            totalRemise += product.remise_value ? +product.remise_value : 0;
          else {
            totalRemise += (product.remise_value * ht) / 100;
          }
        });
      });

      this.TVA.forEach((item) => {
        tva += item.value;
      });

      const totalSansRemise = this.prixHT();
      if (this.remise == "remise") this.devis.remise_value = totalRemise;
      else this.devis.remise_value = +this.devis.remise_value + totalRemise;

      let totalAvecRemise = totalSansRemise;

      if (this.remise == "remise") totalAvecRemise -= this.devis.remise_value;
      else {
        if (this.devis.remise_type == "remise.HT")
          totalAvecRemise -= this.devis.remise_value;
        else
          totalAvecRemise -= (totalAvecRemise * this.devis.remise_value) / 100;
      }

      totalAvecRemise += tva;
      if (Math.round(totalAvecRemise * 100) / 100 < 0) return 0;
      return Math.round(totalAvecRemise * 100) / 100;
    },

    typeRemise() {
      this.devis.remise_value = 0;
      this.devis.groupes.forEach((groupe) => {
        groupe.produits.forEach((product) => {
          product.remise_value = 0;
        });
      });
      this.$forceUpdate();
    },

    pushProduits(i) {
      var produit = {};
      var find = this.devis.groupes[i].produits.findIndex(
        (item) => item.id == this.currentGroup.produit.id
      );

      if (find < 0) {
        if (!this.currentGroup.produit.quantite)
          this.currentGroup.produit.quantite = 1;
        this.currentGroup.produit.remise_value = 0;
        this.currentGroup.produit.remise_type = "remise.HT";

        this.devis.groupes[this.save].produits.push({
          ...this.currentGroup.produit,
        });
      } else {
        produit = this.devis.groupes[i].produits[find];
        this.devis.groupes[i].produits.splice(find, 1);
        produit.quantite++;
        this.devis.groupes[i].produits.splice(find, 0, produit);
      }

      if (this.showDismissibleAlert == true) {
        this.showDismissibleAlert = false;
      }

      for (var j = 0; j < this.devis.groupes.length; j++) {
        this.$refs["produitModal"][j].hide();
      }
    },

    popProduit(i, j) {
      this.devis.groupes[i].produits.splice(j, 1);
      this.prixTTC();
      this.updateTTC();
      this.recalculerTotal();
    },

    popGroup(i) {
      this.group.splice(i, 1);
      this.devis.groupes.splice(i, 1);
    },

    closeGroup(i) {
      this.showGroup[i].bool = !this.showGroup[i].bool;
    },

    copyProduit(i, j) {
      var copy = this.devis.groupes[i].produits[j];
      this.devis.groupes[i].produits.push(copy);
    },

    addProduit(item, type) {
      if (
        this.currentGroup.produit.lib == null ||
        this.currentGroup.produit.id != item.id
      ) {
        item.type_ligne = type;
        this.currentGroup.produit = item;
      }
    },

    updateTTC() {
      this.devis.groupes.push();
      this.prixTTC();
    },
    updateTva() {},

    saveGroup(i) {
      this.save = i;
    },

    addGroup() {
      var groupe = {
        produits: [],
      };
      var group = {
        room: {
          lib: null,
        },
        work: null,
      };
      this.group.push(group);
      this.devis.groupes.push(groupe);
      this.showGroup[this.showGroup.length - 1].bool = false;
      this.showGroup.push({
        bool: true,
      });
    },

    addJoker() {
      var groupe = {
        produits: [],
        joker: true,
      };
      var group = {
        room: {
          lib: "Divers",
        },
        work: null,
      };
      this.group.push(group);
      this.devis.groupes.push(groupe);
      this.showGroup[this.showGroup.length - 1].bool = false;
      this.showGroup.push({
        bool: true,
      });
    },

    addpiece() {
      this.addGroup();
      this.openpiece(this.group.length - 1);
    },

    download() {
      axios({
        url:
          `http://api.batigo.fr/api/devis/download/` + `${this.pdfInfo.devis}`,
        method: "GET",
        responseType: "blob",
        headers: { Authorization: `Bearer ${this.getToken}` },
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "devis.pdf");
        document.body.appendChild(fileLink);
        fileLink.click();
        this.pdfInfo = null;
        this.$router.push("/devis/list");
      });
    },

    controlsize(word, size) {
      if (word.length > size) {
        this.messages = "Vous avez dépassé la taille maximale de ce champs.";
        this.showDismissibleAlert = true;
      } else {
        this.showDismissibleAlert = false;
      }
    },
    async facture() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      this.quit = true;
      var ht = 0;
      var tva = 0;
      var ttc = 0;
      this.devis.groupes.forEach((group) => {
        if (!group.piece) group.piece = "Divers";
        group.produits.forEach((produit) => {
          ht = ht + produit.unit_price;
          ttc = ttc + produit.prix_vente_TTC;
          tva = tva + produit.tva;
        });
      });
      var facture = {
        client_id: this.form2.id,
        modele_id: this.devis.modele_id,
        user_id: this.getOnlineUser.id,
        entreprise_id: this.form.id,
        type_facture: this.fac.type,
        condition_paiment: this.devis.condition_paiment,
        remarque: this.devis.remarque,
        debut_devis: this.devis.debut_devis,
        fin_devis: this.devis.fin_devis,
        groupes: this.devis.groupes,
      };

      if (this.remise == "remise") {
        facture.remise_mode = "par_ligne";
        facture.remise_value = this.devis.remise_value;
        facture.remise_type = this.devis.remise_type;
      } else {
        facture.remise_mode = "global";
        facture.remise_value = this.devis.remise_value;
        facture.remise_type = this.devis.remise_type;
        if (this.devis.remise_type == "remise.HT")
          facture.remise_total = this.devis.remise_value;
        else
          facture.remise_total =
            (this.devis.remise_value * this.prixHT()) / 100;
      }

      if (this.devis.description) {
        facture.description = this.devis.description;
      }

      if (this.devis.visite_technique) {
        facture.visite_technique = this.devis.visite_technique;
      }
      if (this.devis.remise_value) {
        facture.remise_value = this.devis.remise_value;
      }

      if (this.devis.remise_type) {
        facture.remise_type = this.devis.remise_type;
      }

      if (this.devis.moyens_paiement) {
        facture.moyens_paiement = this.devis.moyens_paiement;
      }

      if (this.devis.organisme_preteur) {
        facture.organisme_preteur = this.devis.organisme_preteur;
      }
      if (this.devis.montant_credit) {
        facture.montant_credit = this.devis.montant_credit;
      }
      if (this.devis.taux_nominal) {
        facture.taux_nominal = this.devis.taux_nominal;
      }
      if (this.devis.mensualite) {
        facture.mensualite = this.devis.mensualite;
      }
      if (this.devis.nombre_mensualités) {
        facture.nombre_mensualités = this.devis.nombre_mensualités;
      }
      if (this.devis.taeg) {
        facture.taeg = this.devis.taeg;
      }
      if (this.devis.cout_total_credit) {
        facture.cout_total_credit = this.devis.cout_total_credit;
      }
      if (this.devis.interlocuteur) {
        facture.interlocuteur_id = this.devis.interlocuteur.id;
      }
      if (this.devis.assureance) {
        facture.assureance = this.devis.assureance;
      }
      if (this.devis.assurance_type) {
        facture.assurance_type = this.devis.assurance_type;
      }
      if (this.devis.with_aide) {
        facture.with_aide = this.devis.with_aide;
      }
      if (this.devis.montant_maprimrenov) {
        facture.montant_maprimrenov = this.devis.montant_maprimrenov;
      }
      if (this.devis.montant_cee) {
        facture.montant_cee = this.devis.montant_cee;
      }
      if (this.devis.delegatairecopie) {
        facture.delegataire_id = this.devis.delegatairecopie.id;
      }
      facture.same_travaux_address = 1;
      if (this.devis.bareme_mpr) {
        facture.bareme_mpr = this.devis.bareme_mpr;
      }
      if (this.fac.total_type == "pourcentage")
        facture.total_percent = this.fac.valeur;
      if (this.fac.total_type == "ht") facture.total_HT = this.fac.valeur;
      if (this.fac.total_type == "ttc") facture.total_TTC = this.fac.valeur;
      if (this.isCommercialBatigo) facture.entreprise_id = this.form.id;

      if (this.isAssistant) facture.user_id = this.devis.owner.id;
      if (this.fac.type == "facture.acompte") {
        facture.total_type = this.fac.total_type;
      }
      var valid = true;
      var nbgroup = this.devis.groupes.length;
      for (let i = 0; i < nbgroup; i++) {
        if (this.devis.groupes[i].produits.length == 0) {
          this.messages = "Veuillez ajouter un article";
          this.showDismissibleAlert = true;
          valid = false;
          break;
        }
      }
      if (valid) {
        this.store_facture(facture)
          .then((response) => {
            this.quit = true;
            const factures = response.data.data.facture;
            const lastFacture = factures[factures.length - 1];
            if (lastFacture)
              this.$router.push("/facture/list?id=" + lastFacture.id);
            else this.$router.push("/facture/list");
          })
          .catch((err) => {
            this.quit = false;
            this.messages = err;
            this.showDismissibleAlert = true;
          });
      }
    },
  },

  computed: {
    ...mapGetters([
      "getAllDelegataire",
      "getAllmainoeuvres",
      "getmainoeuvreLoading",
      "getAllforfaits",
      "getforfaitLoading",
      "getTabs",
      "getAllpiece",
      "getLoadingpiece",
      "getAlltravaux",
      "getLoadingtravaux",
      "getAllProducts",
      "getProductLoading",
      "getAllclients",
      "getclientLoading",
      "getAllUsers",
      "getOnlineUserLoading",
      "getOnlineUser",
      "getentreprise",
      "getdevis",
      "getAllentreprises",
      "getentrepriseLoading",
      "getPays",
      "getSelectedLang",
      "getDelegataireLoading",
      "getFactureLoading",
      "getclient",
      "getAllsousTraitant",
      "getLoadingsousTraitant",
    ]),

    TVA() {
      var tva = {};
      this.devis.groupes.forEach((groupe) => {
        groupe.produits.forEach((product) => {
          let ht = 0;

          ht += this.totalAR(product);

          if (this.remise != "remise") {
            if (this.devis.remise_type == "remise.HT") {
              ht -=
                (ht * ((this.devis.remise_value * 100) / this.prixHT())) / 100;
            } else {
              ht -= (this.devis.remise_value * ht) / 100;
            }
          }

          if (tva[product.tva]) {
            tva[product.tva] += (ht * product.tva) / 100;
          } else {
            tva[product.tva] = (ht * product.tva) / 100;
          }
        });
      });
      return Object.keys(tva).map(function (key) {
        return {
          tva: key,
          value: Math.round(tva[key] * 100) / 100,
        };
      });
    },
    Reste() {
      var tva = 0;
      let totaleHT = 0;
      this.devis.groupes.forEach((groupe) => {
        groupe.produits.forEach((product) => {
          let ht = 0;

          ht = ht + parseFloat(product.quantite) * product.unit_price;
          tva += (ht * product.tva) / 100;
          totaleHT += ht;
        });
      });
      const ttc = totaleHT + tva;
      if (this.fac.type == "facture.acompte") {
        if (this.fac.total_type == "pourcentage") {
          let accompt = (ttc * this.fac.valeur) / 100;
          let rest = ttc - accompt;
          return Math.round(rest * 100) / 100;
        } else {
          let accompt = this.fac.valeur;
          let rest = ttc - accompt;
          return Math.round(rest * 100) / 100;
        }
      } else {
        return 0;
      }
    },
    Accompt() {
      var tva = 0;
      let totaleHT = 0;
      this.devis.groupes.forEach((groupe) => {
        groupe.produits.forEach((product) => {
          let ht = 0;
          ht = ht + parseFloat(product.quantite) * product.unit_price;
          tva += (ht * product.tva) / 100;
          totaleHT += ht;
        });
      });
      const ttc = totaleHT + tva;
      if (this.fac.type == "facture.acompte") {
        if (this.fac.total_type == "pourcentage") {
          let accompt = (ttc * this.fac.valeur) / 100;
          return Math.round(accompt * 100) / 100;
        } else {
          let accompt = this.fac.valeur;
          return Math.round(accompt * 100) / 100;
        }
      } else {
        return 0;
      }
    },

    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },
    isCommercialBatigo() {
      return this.getOnlineUser.role == "user.commercial-batigo";
    },
    isAssistant() {
      return this.getOnlineUser.role == "user.assistant";
    },
    DiverExist() {
      var find = this.group.findIndex((item) => item.room.lib == "Divers");

      if (find < 0) return false;
      else return true;
    },
  },

  filters: {
    financial: function (value) {
      return formatMoney(value, {
        symbol: "€",
        thousand: " ",
        precision: 2,
        decimal: ",",
        format: "%v %s",
      });
    },
  },

  // beforeRouteLeave(to, from, next) {
  //   if (!this.quit) {
  //     var answer = window.confirm(this.$t("LOST_WORK"));
  //     if (answer) next();
  //     else return;
  //   }
  //   next();
  // },

  mounted() {
    if (this.$route.query.client) {
      this.client(this.$route.query.client).then(() => {
        this.form2 = this.getclient;
        this.entreprise(this.getclient.entreprise).then(() => {
          this.form = { ...this.getentreprise };
        });
      });
    }

    if (!this.isCommercialBatigo) {
      this.entreprise(this.getOnlineUser.entreprise).then(() => {
        this.form = { ...this.getentreprise };
      });
    }

    if (this.isCommercialBatigo || this.isSuperAdmin) {
      this.all_entreprises({
        page: this.page,
        per_page: this.per_page,
      });
    }
    if (this.isAssistant)
      this.all_users({
        page: this.page,
        per_page: this.per_page,
      });

    var today = new Date();
    var tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    this.devis.debut_devis = today.toLocaleDateString("fr-CA", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });

    this.devis.fin_devis = tomorrow.toLocaleDateString("fr-CA", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    });
    this.devis.visite_technique = this.devis.debut_devis;
  },
};
</script>

<style lang="scss" scoped>
.page-header {
  h1 {
    width: 100%;
  }
}

.body {
  @media only screen and (max-width: 900px) {
    padding: 8px;
    width: 100%;
  }
  width: 97%;
  margin: 0 auto;
  background-color: #fff;
  padding: 24px 42px;
  border-radius: 5px;

  .containerz {
    @media only screen and (max-width: 900px) {
      padding: 8px;
      width: 100%;
      margin: 0;
    }
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 2px 6px 0px rgba(229, 229, 229, 0.75);
    padding: 42px;
  }
}

.groupe_pvw {
  margin: 24px 0;
  border: 1px dotted #28367a;
  width: 100%;
  padding: 4px 8px;
  font-size: 14px;
  color: #28367a;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #28367a;
    color: #fff;
  }
}

.form {
  @media only screen and (max-width: 900px) {
    align-items: center;
  }
  display: flex;
  justify-content: space-between;

  .left,
  .right {
    width: 50%;
  }

  .logo {
    @media only screen and (max-width: 900px) {
      width: 85px;
      height: 80px;
    }
    width: 105px;
    height: 100px;
    background-color: #28367a;
    margin-bottom: 8px;
    border-radius: 10px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 800;
    font-size: 24px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .form-group {
      @media only screen and (max-width: 900px) {
        width: 95%;
      }
      margin-bottom: 6px;
      width: 50%;
      color: #28367a;
      font-size: 12px;

      input {
        padding: 6px 8px;
        color: #0f0f0f;
        font-size: 12px;
        border: 1px solid #b5b5b5;
        border-radius: 5px;
      }
    }

    hr {
      width: 50%;
      height: 1px;
      color: #707070;
      margin-top: 0.7rem;
      margin-bottom: 0.7rem;
    }
  }
  .textarea {
    margin-right: 17px;
  }

  .fieldset {
    @media only screen and (max-width: 900px) {
      width: 95%;
      margin-bottom: 8px;
    }
    width: 50%;
    margin-bottom: 6px;

    .legend {
      font-size: 12px;
      color: #28367a;
      margin-bottom: 4px;
    }

    .input,
    textarea {
      padding: 6px 8px;
      color: #0f0f0f;
      font-size: 12px;
      border: 1px solid #b5b5b5;
      border-radius: 5px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .placeholder {
      @media only screen and (max-width: 900px) {
        font-size: 10px;
      }
      color: #b5b5b5;
      font-size: 11px;
    }
  }

  .justify {
    @media only screen and (max-width: 900px) {
      width: 98%;
      margin-bottom: 8px;
    }
    width: 50%;
    display: flex;
    justify-content: space-between;
    .fieldset {
      @media only screen and (max-width: 900px) {
        width: 45%;
      }
      width: 48%;
    }
  }
}

.input-group {
  margin-bottom: 8px;
  .icon-search {
    position: absolute;
    left: 15px;
    top: 10px;
  }

  input {
    padding: 0 32px;
  }
}

.select {
  margin-bottom: 15px;
  border: 1px solid #707070;
  list-style: none;

  width: 100%;
  & li {
    padding: 6px 0;
    cursor: pointer;
    background-color: #f8f8f8;
    color: #000;
    border: none;
    box-shadow: none;
    transition: all 0.2s ease;
    text-align: center;

    &:hover {
      background-color: #28367a;
      color: #fff;
    }
  }

  .bg-danger {
    color: #fff;
  }
}

.groupe {
  position: relative;
  border: 1px dotted #707070;
  border-top: 0;
  margin: 24px 0;

  .piece {
    width: 100%;
    background-color: #28367a;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 8px;
    border-radius: 4px;

    .type {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    p {
      margin: 0;
      font-size: 14px;
      margin-right: 8px;
    }

    .option {
      @media only screen and (max-width: 900px) {
        width: 20%;
      }
      display: flex;
      justify-content: space-between;
      width: 7%;
    }

    button {
      width: 24px;
      height: 24px;
      color: #fff;
      background-color: #1b2765;
      border: 0;
      outline: 0;
      border-color: #1b2765;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      box-shadow: none !important;
    }
  }

  .corps {
    padding: 8px;
  }

  .travaux {
    width: 100%;
    padding: 4px 8px;
    font-size: 14px;
    color: #fff;
    background-color: #34c38f;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.complement {
  @media only screen and (max-width: 900px) {
    display: block;
  }
  margin-top: 8px;
  display: flex;
  justify-content: space-evenly;
  // justify-content: space-between;
  .block {
    @media only screen and (max-width: 900px) {
      width: 100%;
    }
    width: 30%;
  }
  .form-group {
    @media only screen and (max-width: 900px) {
      font-size: 10px;
    }
    cursor: pointer;
    font-size: 13px;
    width: 100%;
    margin-bottom: 4px;
    color: #28367a;

    .custom-select {
      padding: 6px 8px;
      color: #0f0f0f;
      font-size: 12px;
      border: 1px solid #b5b5b5;
      border-radius: 5px;
    }

    .input {
      width: 100%;
      padding: 6px 8px;
      color: #0f0f0f;
      font-size: 12px;
      border: 1px solid #b5b5b5;
      border-radius: 5px;
    }

    .placeholder {
      @media only screen and (max-width: 900px) {
        font-size: 10px;
      }
      color: #b5b5b5;
      font-size: 11px;
    }
  }
}

.table {
  margin-top: 8px;
  overflow: auto;

  button {
    @media only screen and (max-width: 900px) {
      font-size: 10px;
    }
    background-color: #28367a !important;
    border-radius: 5px;
    height: 38px;
    display: flex;
    align-items: center;
    border: none;
  }

  table {
    margin-bottom: 15px;
    width: 100%;
  }

  thead {
    background-color: #465081;
    color: #fff;
    font-weight: 800;
    text-align: center;
    font-size: 12px;

    td {
      min-width: 100px;
      border: 1px solid #fff;
      vertical-align: middle !important;
      padding: 4px 8px !important;
    }
  }

  td {
    min-width: 100px;
    border: 1px solid #fff;
    vertical-align: middle !important;
    padding: 2px 2px;
    margin: 0 !important;
  }

  tbody tr {
    height: 0;
    background-color: #fff;

    input {
      color: #323232;
      font-size: 11px;
      border-color: #707070;
    }

    .detail_pvw {
      background-color: rgba(#000, 0.1);
      border-radius: 5px;
      width: 100%;
      height: 30px;
    }

    .add {
      width: 100%;
      height: 32px;
      border: 1px solid #e4261b;
      background-color: #e4261b;
      border-radius: 5px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;

      .icons {
        font-size: 14px;
      }
    }
  }

  tbody tr:hover {
    cursor: pointer;

    & .action_col {
      opacity: 1;
      color: #28367a;
      background-color: #fff;
      svg {
        &:hover {
          color: red;
        }
      }
    }
  }

  .action_col {
    @media only screen and (max-width: 900px) {
      opacity: 1;
    }
    display: flex;
    align-items: center;
    justify-content: space-around;
    opacity: 0;

    color: #28367a;
    height: 64px;
  }
}
.sousTraitant {
  color: #2167d9;
}
.subtable {
  display: flex;
  justify-content: flex-end;
  width: 80%;

  .complet {
    @media only screen and (max-width: 900px) {
      margin-right: 10%;
      width: 100%;
    }
    margin-right: 10%;
    width: 100%;
    text-align: center;
    font-size: 12px;
    font-weight: bold;

    .thead {
      @media only screen and (max-width: 900px) {
        padding: 6px !important;
      }
      padding: 12px !important;
      background-color: #465081;
      color: #fff;
      width: 40%;
    }
    td {
      color: #000;
      background-color: #f8f8f8;
    }
  }
}
.textstyle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.submit {
  button {
    @media only screen and (max-width: 900px) {
      width: 145px;
      font-size: 14px;
    }
    position: relative;
    height: 48px;
    width: 40%;
    background-color: #28367a;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 800;
    color: #fff;
  }
}
.remise_block {
  display: flex;

  input {
    text-align: center;
  }

  select {
    width: 72px;
    padding: 4px;
  }
}

.select {
  // background: #465081;
  color: #465081;
  padding: 0;
  font-size: 12px;
  text-align: center;
  margin: 0;
}
</style> -->
<template>
  <div
    class="inner-container"
    :class="{ 'min-container': !getTabs }"
    v-if="getdevisMonoFiche"
  >
    <div class="page-header">
      <h1>{{ $t("CREATION-DEV-MAN") }}</h1>
    </div>
    <form class="bodys">
      <div class="containerz">
        <div class="">
          <div class="row my-2">
            <div
              class="col-lg-6 col-md-6 col-sm-12"
              :class="{
                disabledBlock:
                  this.getdevisMonoFiche.data.devis.entreprise.id !==
                    this.getOnlineUser.entreprise.id && isSuperAdmin,
              }"
            >
              <b-form-group :label="$t('COMPANY_ID')" v-if="isSuperAdmin">
                <multiselect
                  :searchable="true"
                  :close-on-select="true"
                  :multiple="false"
                  :options="getAllentreprises"
                  label="lib"
                  track-by="id"
                  v-model="selectedEntreprise"
                >
                  <template slot="noResult">
                    {{ $t("NO_DATA_FOUND") }}
                  </template>
                </multiselect>
              </b-form-group>
            </div>
          </div>

          <hr v-if="isSuperAdmin" />

          <div
            :class="{
              disabledBlock:
                this.getdevisMonoFiche.data.devis.entreprise.id ===
                  this.getOnlineUser.entreprise.id && isSuperAdmin,
            }"
          >
            <div>
              <b>{{ $t("Généralité") }}</b>
            </div>
            <div class="row my-2">
              <div class="col-lg-4 col-md-4 col-sm-12">
                <b-form-group :label="$t('DATE_VISITE_TECHNIQUE')">
                  <b-form-input
                    type="date"
                    v-model="getdevisMonoFiche.data.devis.visite_technique"
                    class="form-input custom-placeholder custom-input"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <b-form-group :label="$t('DATE_START_DEVIS')">
                  <b-form-input
                    type="date"
                    v-model="getdevisMonoFiche.data.devis.debut_devis"
                    required
                    class="form-input custom-placeholder custom-input"
                  ></b-form-input>
                </b-form-group>
              </div>

              <div class="col-lg-4 col-md-4 col-sm-12">
                <b-form-group :label="$t('DATE_END_DEVIS')">
                  <b-form-input
                    type="date"
                    v-model="getdevisMonoFiche.data.devis.fin_devis"
                    required
                    class="form-input custom-placeholder custom-input"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12">
                <b-form-group :label="$t('Debut travaux')" class="">
                  <b-form-input
                    type="date"
                    v-model="getdevisMonoFiche.data.devis.debut_travaux"
                    required
                    class="form-input custom-placeholder custom-input"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <b-form-group :label="$t('Fin travaux')">
                  <b-form-input
                    type="date"
                    required
                    v-model="getdevisMonoFiche.data.devis.fin_travaux"
                    class="form-input custom-placeholder custom-input"
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
            <hr />
            <div class="">
              <div class="">{{ $t("CUSTOMER") }}</div>
              <div class="flex items-center space-x-2">
                <div class="w-full sm:w-1/2 lg:w-750px">
                  <multiselect
                    v-model="selectedClient"
                    :searchable="true"
                    :close-on-select="true"
                    :multiple="false"
                    :options="getAllclients"
                    label="nom"
                    track-by="id"
                    :placeholder="$t('Select_agent')"
                    class="form-input custom-placeholder custom-input"
                    @input="updateClient"
                    style="width: 100%"
                  />
                </div>
                <div>
                  <button
                    class="py-0 newstore rounded"
                    @click="openModal('client')"
                    id="buttonclient-with-popover"
                  >
                    +
                  </button>
                  <b-popover
                    target="buttonclient-with-popover"
                    placement="top"
                    triggers="hover focus"
                  >
                    {{ $t("Vous pouvez ajouter un nouveau client") }}
                  </b-popover>
                  <clientmodale @updateList="setup()" />
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <b-form-checkbox
                v-model="isSameTravauxAddress"
                @change="handleCheckboxChange"
                :label="$t('QUESTION_ADRESSE')"
                class="custom-input"
              >
                {{ $t("MEME_ADRESSE_TRAVAUX") }}
              </b-form-checkbox>
            </div>
            <div class="row" v-if="isSameTravauxAddress">
              <div class="col-lg-4 col-md-4 col-sm-12">
                <b-form-group :label="$t('TRAVAUX_RUE')">
                  <b-form-input
                    :value="getdevisMonoFiche.data.devis.rue_travaux"
                    :placeholder="$t('TRAVAUX_RUE')"
                    class="form-input custom-placeholder custom-input"
                    readonly
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <b-form-group :label="$t('TRAVAUX_CP')">
                  <b-form-input
                    @paste="
                      (event) =>
                        onPaste(
                          event,
                          'getdevisMonoFiche.data.devis.cp_travaux'
                        )
                    "
                    @keydown="(event) => onKeyDown(event)"
                    @input="validatePostalCode"
                    type="number"
                    maxlength="5"
                    max="99999"
                    min="0"
                    :value="getdevisMonoFiche.data.devis.cp_travaux"
                    :placeholder="$t('TRAVAUX_CP')"
                    class="form-input custom-placeholder custom-input"
                    readonly
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <b-form-group :label="$t('TRAVAUX_VILLE')">
                  <b-form-input
                    @keydown="preventNumberInput"
                    :value="getdevisMonoFiche.data.devis.ville_travaux"
                    :placeholder="$t('TRAVAUX_VILLE')"
                    class="form-input custom-placeholder custom-input"
                    readonly
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <b-form-group :label="$t('TRAVAUX_RUE')">
                  <b-form-input
                    v-model="getdevisMonoFiche.data.devis.rue_travaux"
                    :placeholder="$t('TRAVAUX_RUE')"
                    :state="validationStateRue"
                    class="form-input custom-placeholder custom-input"
                    @input="
                      validateRue();
                      updateDevis(
                        'rue_travaux',
                        getdevisMonoFiche.data.devis.rue_travaux
                      );
                    "
                  ></b-form-input>
                  <b-form-invalid-feedback :state="validationStateRue">
                    {{ $t("REQUIRED_FIELD") }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <b-form-group :label="$t('TRAVAUX_CP')">
                  <b-form-input
                    @paste="
                      (event) =>
                        onPaste(
                          event,
                          'getdevisMonoFiche.data.devis.cp_travaux'
                        )
                    "
                    @keydown="(event) => onKeyDown(event)"
                    @input="validatePostalCode"
                    type="number"
                    maxlength="5"
                    max="99999"
                    required
                    min="0"
                    v-model="getdevisMonoFiche.data.devis.cp_travaux"
                    :placeholder="$t('TRAVAUX_CP')"
                    class="form-input custom-placeholder custom-input"
                    :state="validationState"
                  ></b-form-input>

                  <b-form-invalid-feedback :state="validationState">
                    {{ cpErrorMessage }}
                    {{ $t("REQUIRED_FIELD") }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <b-form-group :label="$t('TRAVAUX_VILLE')">
                  <b-form-input
                    v-model="getdevisMonoFiche.data.devis.ville_travaux"
                    :placeholder="$t('TRAVAUX_VILLE')"
                    @keydown="preventNumberInput"
                    :state="validationStateville"
                    class="form-input custom-placeholder custom-input"
                    @input="
                      validateVille();
                      updateDevis(
                        'ville_travaux',
                        getdevisMonoFiche.data.devis.ville_travaux
                      );
                    "
                  ></b-form-input>
                  <b-form-invalid-feedback :state="validationStateville">
                    {{ $t("REQUIRED_FIELD") }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </div>
            </div>
            <hr />
            <div class="flex items-center mb-3">
              <b>{{ $t("Ajoutez une nouvelle section") }}</b>
              <button
                class="ml-2 py-0 px-2 newstore rounded"
                @click="addGroup"
                id="button-with-popover"
              >
                +
              </button>
              <b-popover
                target="button-with-popover"
                placement="top"
                triggers="hover focus"
              >
                {{ $t("Vous pouvez ajouter un nouveau groupe de travaux") }}
              </b-popover>
            </div>
            <!-- <div class="d-flex justify-content-end gap-2">
              <div>
                <button
                  class="mb-1 py-0 mr-1 new rounded"
                  @click="openModal('Product')"
                >
                  {{ $t("PRODUIT") }}
                </button>
                <articlemodale />
              </div>
              <div>
                <button
                  class="mb-1 py-0 mr-1 new rounded"
                  @click="openModal('Forfait')"
                >
                  {{ $t("FORFAIT") }}
                </button>
                <forfaitmodale />
              </div>
              <div>
                <button
                  class="mb-1 py-0 mr-1 new rounded"
                  @click="openModal('Main d\'oeuvre')"
                >
                  {{ $t("MAIN_D_OEUVRE") }}
                </button>
                <mainoeuvremodale />
              </div>
            </div> -->

            <div
              v-for="(group, groupIndex) in getdevisMonoFiche.data
                .groupeLigneDocument || []"
              :key="group.id"
              class=""
            >
              <div class="position">
                <div class="title text-center">
                  <b-form-input
                    v-model="
                      getdevisMonoFiche.data.groupeLigneDocument[groupIndex]
                        .traveaux
                    "
                    :placeholder="$t('nom de group')"
                    maxlength="10"
                    class="custom-input-small pl-4"
                    id="input-with-popover"
                  />
                  <b-popover
                    target="input-with-popover"
                    placement="top"
                    triggers="hover focus"
                  >
                    {{ $t("Vous pouvez ajouter un nom à votre groupe") }}
                  </b-popover>
                </div>
              </div>
              <div
                class="d-flex justify-content-center align-items-center flex-column border_pointe scroll-container"
              >
                <div class="col-lg-12 col-sm-12 col-md-10">
                  <div class="checkbox-wrapper">
                    <div class="checkbox-container">
                      <v-checkbox
                        id="input-with-popover"
                        v-model="isChecked[groupIndex]"
                        :label="$t('AJUTER_DESCRIPTION')"
                        @change="handleCheckboxChanges(groupIndex)"
                      ></v-checkbox>
                    </div>
                  </div>

                  <b-popover
                    target="input-with-popover"
                    placement="top"
                    triggers="hover focus"
                  >
                    {{
                      $t(
                        "Vous pouvez ajouter toutes les descriptions liées à ce groupe de travail"
                      )
                    }}
                  </b-popover>

                  <vue-editor
                    v-if="isChecked[groupIndex]"
                    v-model="group.description"
                    :placeholder="$t('AJUTER_DESCRIPTION')"
                    class="mt-4 vue-editor"
                    :editor-toolbar="customToolbar"
                  />

                  <div class=" ">
                    <div>
                      <div class=" ">
                        <form class="my-4 w-100">
                          <div class="container-wrappe">
                            <div class="d-flex justify-content-end mt-4 px-0">
                              <b-dropdown
                                :text="$t('ADD')"
                                class="button-pre-style mb-2"
                                variant="primary"
                                right
                              >
                                <b-dropdown-item
                                  @click="addRow('Product', groupIndex)"
                                >
                                  {{ $t("PRODUIT") }}
                                </b-dropdown-item>
                                <b-dropdown-item
                                  @click="addRow('Forfait', groupIndex)"
                                >
                                  {{ $t("FORFAIT") }}
                                </b-dropdown-item>
                                <b-dropdown-item
                                  @click="addRow('Main d\'oeuvre', groupIndex)"
                                >
                                  {{ $t("MAIN_D_OEUVRE") }}
                                </b-dropdown-item>
                              </b-dropdown>
                            </div>
                          </div>
                          <div class="center">
                            <div class="d-flex justify-content-center">
                              <div class="borders">
                                <div class="">
                                  <div class="content-wrapper colorred">
                                    <div>
                                      <div class="header d-flex mb-3 px-3 pt-2">
                                        <div
                                          class="header-item"
                                          style="flex: 2.1"
                                        >
                                          {{ $t("PRODUIT") }}
                                        </div>
                                        <div
                                          class="header-item"
                                          style="flex: 1"
                                        >
                                          {{ $t("Marque") }}
                                        </div>
                                        <div
                                          class="header-item"
                                          style="flex: 1"
                                        >
                                          {{ $t("REF") }}
                                        </div>
                                        <div
                                          class="header-item"
                                          style="flex: 2"
                                        >
                                          {{ $t("WORDING") }}
                                        </div>
                                        <div
                                          class="header-item"
                                          style="flex: 0.7"
                                        >
                                          {{ $t("QTE") }}
                                        </div>
                                        <div
                                          class="header-item"
                                          style="flex: 1.3"
                                        >
                                          {{ $t("type_uniteU") }}
                                        </div>
                                        <div
                                          class="header-item"
                                          style="flex: 1.2"
                                        >
                                          {{ $t("PUHT") }}
                                        </div>
                                        <div
                                          class="header-item"
                                          style="flex: 1.4"
                                        >
                                          {{ $t("TOTALHT") }}
                                        </div>
                                        <div
                                          class="header-item"
                                          style="
                                            flex: 0.4;
                                            border-radius: 0 5px 0 0;
                                          "
                                        >
                                          {{ $t("VAT") }}
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      v-if="
                                        !(
                                          group.products &&
                                          group.products.length
                                        )
                                      "
                                      class="text-center my-4 pb-4"
                                    >
                                      {{ $t("NO_DATA_FOUND") }}
                                    </div>

                                    <div v-else>
                                      <div
                                        v-for="(
                                          row, productIndex
                                        ) in group.products || []"
                                        :key="productIndex"
                                        class="row-item d-flex"
                                      >
                                        <form @submit.prevent="handleSubmit">
                                          <div class="row m-2">
                                            <div
                                              class="input-wrapper mx-2"
                                              style="flex: 2.75"
                                            >
                                              <!-- {{ row.type_ligne }} -->
                                              <div
                                                v-if="row.id"
                                                class="text-container"
                                              >
                                                {{ row.lib }}
                                              </div>
                                              <searchInput
                                                v-if="row.type === 'Product'"
                                                :list="filteredProducts"
                                                label="lib"
                                                :placeholder="$t('PRODUIT')"
                                                :searchFunc="allproducts"
                                                @searchfilter="
                                                  addProduit(
                                                    $event,
                                                    productIndex,
                                                    groupIndex
                                                  )
                                                "
                                                :filtre="{
                                                  entreprise:
                                                    getdevisMonoFiche.data
                                                      .entreprise.id,
                                                }"
                                                :disabled="row.ref !== ''"
                                                class="truncated-label"
                                              />
                                              <searchInput
                                                v-if="row.type === 'Forfait'"
                                                :list="filteredForfaits"
                                                label="lib"
                                                :placeholder="$t('FORFAIT')"
                                                :searchFunc="allForfait"
                                                @searchfilter="
                                                  addProduit(
                                                    $event,
                                                    productIndex,
                                                    groupIndex
                                                  )
                                                "
                                                :disabled="row.ref !== ''"
                                                :filtre="{
                                                  entreprise:
                                                    getdevisMonoFiche.data
                                                      .entreprise.id,
                                                }"
                                              />
                                              <searchInput
                                                v-if="
                                                  row.type === 'Main d\'oeuvre'
                                                "
                                                :list="filteredMainOeuvres"
                                                label="lib"
                                                :placeholder="
                                                  $t('MAIN_D_OEUVRE')
                                                "
                                                :searchFunc="allmainoeuvres"
                                                @searchfilter="
                                                  addProduit(
                                                    $event,
                                                    productIndex,
                                                    groupIndex
                                                  )
                                                "
                                                :disabled="row.ref !== ''"
                                                :filtre="{
                                                  entreprise:
                                                    getdevisMonoFiche.data
                                                      .entreprise.id,
                                                }"
                                              />
                                            </div>

                                            <div
                                              class="input-wrapper mx-2"
                                              style="flex: 1"
                                            >
                                              <b-form-input
                                                v-model="row.fabricant"
                                                :disabled="
                                                  row.type === 'Forfait' ||
                                                  row.type_ligne ===
                                                    'Forfait' ||
                                                  row.type ===
                                                    'Main d\'oeuvre' ||
                                                  row.type_ligne ===
                                                    'Main d\'oeuvre'
                                                "
                                                maxlength="10"
                                                class="custom-input"
                                              />
                                            </div>
                                            <div
                                              class="input-wrapper mx-2"
                                              style="flex: 1"
                                            >
                                              <b-form-input
                                                v-model="row.ref"
                                                maxlength="10"
                                                class="custom-input"
                                              />
                                            </div>
                                            <div
                                              class="input-wrapper ms-2"
                                              style="flex: 3"
                                            >
                                              <b-form-input
                                                class="custom-input"
                                                v-model="row.lib"
                                                @input="
                                                  row.lib = $event.toUpperCase()
                                                "
                                                required
                                                maxlength="100"
                                              />
                                            </div>
                                            <div
                                              class="input-wrapper mx-2"
                                              style="flex: 1"
                                            >
                                              <b-form-input
                                                class="custom-input"
                                                type="number"
                                                @paste="
                                                  (event) =>
                                                    onPaste(
                                                      event,
                                                      'row.quantite'
                                                    )
                                                "
                                                v-model.number="row.quantite"
                                                @keydown="
                                                  (event) => onKeyDown(event)
                                                "
                                                min="1"
                                                required
                                              />
                                            </div>
                                            <div
                                              class="input-wrapper mx-2"
                                              style="flex: 1.5"
                                            >
                                              <b-form-select
                                                class="custom-input"
                                                v-model="row.type_unite"
                                                :options="optionstype"
                                                text-field="text"
                                                value-field="value"
                                                required
                                              />
                                            </div>
                                            <div
                                              class="input-wrapper mx-2"
                                              style="flex: 1.5"
                                            >
                                              <b-form-input
                                                class="custom-input"
                                                type="number"
                                                @paste="
                                                  (event) =>
                                                    onPaste(
                                                      event,
                                                      'row.unit_price'
                                                    )
                                                "
                                                v-model.number="row.unit_price"
                                                @keydown="
                                                  (event) => onKeyDown(event)
                                                "
                                                required
                                                step="1"
                                                min="0"
                                                max="10000000.00"
                                              />
                                            </div>
                                            <div
                                              class="input-wrapper mx-2"
                                              style="flex: 1.5"
                                            >
                                              <b-form-input
                                                class="custom-input"
                                                :value="totalHT(row)"
                                                readonly
                                              />
                                            </div>
                                            <div
                                              class="input-wrapper mx-2"
                                              style="flex: 1"
                                            >
                                              <b-form-select
                                                class="custom-input"
                                                v-model.number="row.tva"
                                                :options="optionsTVA"
                                                required
                                              />
                                            </div>
                                          </div>

                                          <div class="row input-wrapper m-2">
                                            <div style="flex: 0.5"></div>
                                            <div class="mx-2" style="flex: 0.5">
                                              {{ $t("DESC") }}
                                            </div>
                                          </div>
                                          <div class="row input-wrapper m-2">
                                            <div style="flex: 2"></div>
                                            <div style="flex: 12">
                                              <vue-editor
                                                v-model="row.desc"
                                                :placeholder="
                                                  $t('AJUTER_DESCRIPTION')
                                                "
                                                class="mt-4"
                                                :editor-toolbar="customToolbar"
                                              />
                                            </div>
                                          </div>
                                          <div class="row m-2">
                                            <div style="flex: 13"></div>
                                            <div style="flex: 1">
                                              <b-button
                                                @click="
                                                  removeRow(step, productIndex)
                                                "
                                                class="button-pre-style custom-input"
                                                id="ligne-with-popover"
                                              >
                                                <b-icon icon="trash"></b-icon>
                                              </b-button>
                                              <b-popover
                                                target="ligne-with-popover"
                                                placement="top"
                                                triggers="hover focus"
                                              >
                                                {{
                                                  $t(
                                                    "Vous pouvez supprimer ce produit"
                                                  )
                                                }}
                                              </b-popover>
                                            </div>
                                          </div>

                                          <hr
                                            v-if="
                                              productIndex <
                                              getdevisMonoFiche.data
                                                .groupeLigneDocument[groupIndex]
                                                ?.products.length -
                                                1
                                            "
                                          />
                                        </form>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row m-2">
                <div style="flex: 13"></div>
                <div style="flex: 1">
                  <b-button
                    @click="removeGroup(groupIndex, group.id)"
                    class="button-pre-style custom-input"
                    id="group-with-popover"
                  >
                    <b-icon icon="trash"></b-icon>
                  </b-button>
                  <b-popover
                    target="group-with-popover"
                    placement="top"
                    triggers="hover focus"
                  >
                    {{ $t("Vous pouvez supprimer ce groupe") }}
                  </b-popover>
                </div>
              </div>
            </div>

            <hr />
            <div>
              <b>{{ $t("PAYMENT") }}</b>
            </div>
            <div class="row mb-4">
              <div class="fieldset col-8">
                <div class="legend mb-2">{{ $t("MOD_PAYMENT") }}</div>

                <multiselect
                  v-model="selectedMoyensPaiement"
                  :searchable="false"
                  :close-on-select="true"
                  :options="optionspaiement"
                  :multiple="true"
                  :placeholder="$t('sélectionnez_moy')"
                  label="text"
                  track-by="value"
                  required
                  class="form-input custom-placeholder custom-input custom-margin-bottom"
                ></multiselect>
              </div>
            </div>

            <hr />
            <div>
              <b>{{ $t("REMARQUE") }}</b
              >/<b>{{ $t("COND_REG") }}</b>
            </div>
            <vue-editor
              v-model="getdevisMonoFiche.data.devis.remarque"
              :placeholder="$t('REMARQUE')"
              class="mt-4"
              :editor-toolbar="customToolbar"
            />
            <!-- <div class="row">
              <div class="col-lg-6 cool-md-6 col-sm-12">
                <div class="fieldset textarea">
                  <div class="legend">{{ $t("REMARQUE") }}</div>
                  <b-form-textarea
                    id="textarea-large"
                    v-model="getdevisMonoFiche.data.devis.remarque"
                    size="lg"
                    :placeholder="$t('REMARQUE')"
                    maxlength="1000000000000000000000000000000000000000000"
                  ></b-form-textarea>
                </div>
              </div>
              <div class="col-lg-6 cool-md-6 col-sm-12">
                <div class="fieldset">
                  <div class="legend">{{ $t("COND_REG") }}</div>
                  <b-form-textarea
                    v-model="getdevisMonoFiche.data.devis.conditions_reglements"
                    size="lg"
                    :placeholder="$t('COND_REG')"
                    required
                    maxlength="1900000000000000000000000000000000000000"
                  ></b-form-textarea>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end mt-5 submit">
        <b-button
          variant="success"
          @click="updatedevis"
          :title="$t('CHOOSE') + ' ' + $t('PRODUCT')"
          class="d-flex justify-content-center align-items-center"
        >
          {{ $t("GENERATE") }} {{ $t("ESTIMATE") }}
          <div v-if="getdevisLoading" class="ml-2">
            <div class="spinner-border" role="status"></div>
          </div>
        </b-button>
      </div>
    </form>
    <Snackbar ref="snackbar" class="mt-5" />

    <CommentMarche to="/how-it-work/devis"></CommentMarche>
  </div>
</template>

<script>
import Snackbar from "@/components/ui/errorSnackbar.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import domain from "@/environment";
import axios from "axios";
import searchInput from "@/components/ui/searchInput";
import CommentMarche from "../components/commentMarche.vue";
import clientmodale from "@/components/Ajoutmodel/clientmodale.vue";
import { preventExceedingLengthFloat, handlePaste } from "@/utils/inputUtils";

export default {
  components: {
    searchInput,
    clientmodale,
    CommentMarche,
    Snackbar,
  },

  data() {
    return {
      isChecked: [],

      columns: [
        {
          label: "PRODUIT",
          model: "product",
          type: "",
          style: { flex: 2 },
          required: true,
        },
        {
          label: "REF",
          model: "ref",
          type: "text",
          style: { flex: 1 },
          required: false,
        },
        {
          label: "WORDING",
          model: "lib",
          type: "text",
          style: { flex: 1 },
          required: false,
        },
        {
          label: "QTE",
          model: "quantite",
          type: "number",
          style: { flex: 1 },
          required: true,
        },
        {
          label: "type_uniteU",
          model: "type_unite",
          type: "select",
          style: { flex: 1 },
          options: [
            { value: "m2", text: this.$t("m2") },
            { value: "m3", text: this.$t("m3") },
            { value: "ml", text: this.$t("ML") },
            { value: "h", text: this.$t("h") },
            { value: "F", text: this.$t("FORFAIT") },
            { value: "U", text: this.$t("unitaire") },
          ],
        },
        {
          label: "PUHT",
          model: "unit_price",
          type: "number",
          style: { flex: 1.5 },
          required: true,
        },
        {
          label: "TOTALHT",
          model: "totalHT",
          type: "number",
          style: { flex: 1 },
          readonly: true,
        },
        {
          label: "VAT",
          model: "tva",
          type: "select",
          style: { flex: 1 },
          options: [
            { value: 0, text: "0%" },
            { value: 5.5, text: "5.5%" },
            { value: 10, text: "10%" },
            { value: 20, text: "20%" },
          ],
        },
      ],
      rows: [],
      isEditorVisible: false,
      content: "",
      customToolbar: [
        ["bold", "italic", "underline", "strike"], // Mise en forme du texte
        [{ header: [1, 2, 3, 4, 5, 6, false] }], // En-têtes h1, h2, h3, h4, h5, h6
        [{ font: [] }], // Sélection de la police
        // [{ size: ["small", false, "large", "huge"] }], // Taille de la police
        [{ color: [] }, { background: [] }], // Couleur de texte et de fond
        [{ align: [] }], // Alignement (gauche, centre, droite, justifié)
        [{ list: "ordered" }, { list: "bullet" }], // Listes ordonnées et à puces
        [{ script: "sub" }, { script: "super" }], // Indices et exposants
        [{ indent: "-1" }, { indent: "+1" }], // Indentation
        ["blockquote", "code-block"], // Bloc de citation et bloc de code
        ["link", "image", "video"], // Lien, image et vidéo
        ["clean"], // Nettoyage de la mise en forme
      ],

      open: false,
      active: false,
      selectedEntreprise: null,
      groupProductIds: null,
      groupes: [],
      optionstype: [
        { value: "m2", text: this.$t("m2") },
        { value: "m3", text: this.$t("m3") },
        { value: "ml", text: this.$t("ML") },
        { value: "h", text: this.$t("h") },
        { value: "F", text: this.$t("F") },
        { value: "U", text: this.$t("u") },
      ],
      optionsTVA: [
        { value: 0, text: "0%" },
        { value: 1, text: "1%" },
        { value: 5.5, text: "5.5%" },
        { value: 10, text: "10%" },
      ],
      validationStateville: null,
      validationState: null,
      validationStateRue: null,
      cpErrorMessage: "",
      step: 0,
      paiement_state: [],
      isModalOpen: false,
      optionspaiement: [
        {
          value: "Organisme_de_financement",
          text: this.$t("Organisme_de_financement"),
        },
        {
          value: "cheque_virement_espece",
          text: this.$t("Cheque_virement_espece"),
        },
        {
          value: "Certificat_Economie_energie",
          text: this.$t("Certificat_Economie_energie"),
        },
        {
          value: "Ma_prime_renove",
          text: this.$t("Ma_prime_renove"),
        },
        {
          value: "Autre",
          text: this.$t("Autre"),
        },
      ],
      selectedMoyensPaiement: [],
      selectedMondataire: null,
      selectedClient: null,
      search: "",
      page: 1,
      per_page: 10,
      loading: false,
    };
  },
  watch: {
    selectedEntreprise: {
      handler() {
        this.updateActiveStatus();
        this.setup();
      },
      immediate: true,
    },
    "getdevisMonoFiche.data.groupeLigneDocument": {
      handler(newGroups) {
        this.isChecked = newGroups.map((group) => group.description !== "");
      },
      deep: true,
    },
    "group.description"(newValue) {
      // Cocher la case si la description n'est pas vide
      this.isChecked = newValue !== "";
    },
    "getdevisMonoFiche.data.devis.client"(newClient) {
      this.selectedClient = newClient;
    },
    "getdevisMonoFiche.data.devis": {
      handler(newDevis) {
        if (newDevis) {
          this.initializeDateData();
          this.setup();

          this.formatDates();
        }
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions([
      "updateGroupe",
      "deleteGroupe",
      "fetchOneGroup",
      "addGroupe",
      "deleteProduitOfGroup",
      "createoneProductOfGroup",
      "updateProduct",
      "updatedevisMonoFiche",
      'store_facture',
      "getoneMonodevis",
      "setDevisMonoFiche",
      "all_mainoeuvres",
      "all_forfaits",
      "all_products",
      "entreprise",
      "all_clients",
      "all_entreprises",
      "allsousTraitant",
      "store_productJSON",
      "store_forfaitjson",
      "store_mainoeuvreJSON",
    ]),

    handleCheckboxChanges(groupIndex) {
      if (!this.isChecked[groupIndex]) {
        this.getdevisMonoFiche.data.groupeLigneDocument[
          groupIndex
        ].description = "";
      } else {
        this.getdevisMonoFiche.data.groupeLigneDocument[
          groupIndex
        ].description = "";
      }
      this.setDevisMonoFiche(this.getdevisMonoFiche);
    },
    toggleEditor(groupIndex) {
      event.preventDefault();
      this.isEditorVisible = !this.isEditorVisible;
      if (
        this.getdevisMonoFiche.data?.groupeLigneDocument[groupIndex]
          ?.description
      ) {
        this.isEditorVisible = false;
        this.getdevisMonoFiche.data.groupeLigneDocument[
          groupIndex
        ].description = "";

        this.setDevisMonoFiche(this.getdevisMonoFiche);
      }
    },
    allForfait(search) {
      this.all_forfaits({
        page: this.page,
        per_page: 100000000,
        search: search.search,
      });
    },
    allproducts(search) {
      this.all_products({
        page: this.page,
        per_page: 100000000,
        search: search.search,
      });
    },
    allmainoeuvres(search) {
      this.all_mainoeuvres({
        page: this.page,
        per_page: 100000000,
        search: search.search,
      });
    },
    addProduits(selectedItem, index) {
      this.addProduit(selectedItem, index);
    },
    setup() {
      this.all_clients({
        page: 1,
        per_page: 1000000,
        entreprise_id: this.getdevisMonoFiche?.data?.devis?.entreprise.id,
      });
    },
    updateClient(client) {
      this.getdevisMonoFiche.data.devis.client = client;
    },
    updateActiveStatus() {
      this.active = this.entrepriseUpdated;
      if (this.entrepriseupdated) {
        this.getdevisMonoFiche.data.devis.entreprise_id =
          this.selectedEntreprise.id;
        this.getdevisMonoFiche.data.devis.entreprise = this.selectedEntreprise;
        this.setup();

        this.setDevisMonoFiche(this.getdevisMonoFiche);
      }
    },
    initializeDateData() {
      const devis = this.getdevisMonoFiche?.data?.devis || {};

      if (!devis.visite_technique) {
        devis.visite_technique = new Date().toISOString().slice(0, 10);
      }
      if (!devis.debut_devis) {
        devis.debut_devis = new Date().toISOString().slice(0, 10);
      }
      if (!devis.fin_devis) {
        const futureDate = new Date();
        futureDate.setDate(futureDate.getDate() + 30);
        devis.fin_devis = futureDate.toISOString().slice(0, 10);
      }
      if (!devis.debut_travaux) {
        devis.debut_travaux = new Date().toISOString().slice(0, 10);
      }
      if (!devis.fin_travaux) {
        const futureDate = new Date();
        futureDate.setDate(futureDate.getDate() + 60);
        devis.fin_travaux = futureDate.toISOString().slice(0, 10);
      }
    },
    async addGroup() {
      event.preventDefault();
      const groupProduit = {
        document_id: this.getdevisMonoFiche.data.id,
        type_group: "Libre",
      };

      try {
        const newGroup = await this.addGroupe(groupProduit);

        if (newGroup) {
          const groupProductes = await this.fetchOneGroup(newGroup.id);
          this.$refs.snackbar.showSnackbar("Le groupe est bien ajouté");
          if (!this.getdevisMonoFiche.data.groupeLigneDocument) {
            this.$set(this.getdevisMonoFiche.data, "groupeLigneDocument", []);
          }

          this.getdevisMonoFiche.data.groupeLigneDocument.push(groupProductes);

          this.setDevisMonoFiche(this.getdevisMonoFiche);
          this.step++;
        }
      } catch (error) {
        throw new Error(error);
      }
    },
    formatDateLocal(dateString) {
      if (!dateString) return "";
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },

    formatDates() {
      const devis = this.getdevisMonoFiche?.data?.devis;
      if (devis) {
        devis.visite_technique = this.formatDateLocal(devis.visite_technique);
        devis.debut_devis = this.formatDateLocal(devis.debut_devis);
        devis.fin_devis = this.formatDateLocal(devis.fin_devis);
        devis.debut_travaux = this.formatDateLocal(devis.debut_travaux);
        devis.fin_travaux = this.formatDateLocal(devis.fin_travaux);
      }
    },
    onKeyDown(event) {
      preventExceedingLengthFloat(event);
    },
    onPaste(event, fieldName) {
      handlePaste.call(this, event, fieldName);
    },
    validatePostalCode() {
      const cpValue = this.getdevisMonoFiche.data.devis.cp_travaux;

      if (
        cpValue.length >= 4 &&
        cpValue.length <= 5 &&
        /^[0-9]{4,5}$/.test(cpValue)
      ) {
        this.validationState = true;
        this.cpErrorMessage = "";
      } else {
        this.validationState = false;
        this.cpErrorMessage =
          "Please enter a valid postal code (4 to 5 digits).";
      }
    },
    preventNumberInput(event) {
      const key = event.key;

      if (key >= "0" && key <= "9") {
        event.preventDefault();
      }
    },
    validateRue() {
      const rueValue = this.getdevisMonoFiche.data.devis.rue_travaux;

      if (rueValue.trim() !== "") {
        this.validationStateRue = true;
      } else {
        this.validationStateRue = false;
      }
    },
    validateVille() {
      const villeValue = this.getdevisMonoFiche.data.devis.ville_travaux;

      if (villeValue.trim() !== "") {
        this.validationStateville = true;
      } else {
        this.validationStateville = false;
      }
    },
    async getPaiement(devis_ID) {
      try {
        const response = await axios.get(domain + `/devis_moyens_paiement`, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
          params: {
            devis_id: devis_ID,
          },
        });

        return response.data.data;
      } catch (error) {
        throw new Error(
          error.response?.data?.message ||
            "An error occurred while fetching the payment information."
        );
      }
    },
    async postPaiement(payload) {
      const responses = [];
      for (const item of payload) {
        try {
          const dataToSend = {
            devis_id: this.getdevisMonoFiche.data.devis.id,
            moyen_paiement: item,
          };

          const response = await axios.post(
            domain + `/devis_moyens_paiement`,
            dataToSend,
            {
              headers: {
                Authorization: `Bearer ` + this.token,
              },
            }
          );

          responses.push(response);
        } catch (error) {
          throw new Error("Failed to post payment.");
        }
      }
      return responses;
    },
    async deletePaiement(ids) {
      const responses = [];

      for (const id of ids) {
        try {
          const response = await axios.delete(
            domain + `/devis_moyens_paiement/${id}`,
            {
              headers: {
                Authorization: `Bearer ${this.token}`,
              },
            }
          );
          responses.push(response.data);
        } catch (error) {
          responses.push({
            id,
            error:
              error.response?.data?.message ||
              "An error occurred while deleting the payment.",
          });
        }
      }

      return responses;
    },
    paiement() {
      if (!this.paiement_state || !Array.isArray(this.paiement_state)) {
        return;
      }

      const selectedValues =
        this.selectedMoyensPaiement?.map((option) => option.value) || [];

      const idsExistants = selectedValues.filter(
        (moyen_paiement) =>
          !this.paiement_state.some(
            (obj) => obj.moyen_paiement === moyen_paiement
          )
      );

      if (idsExistants) {
        this.postPaiement(idsExistants);
      }

      const idsNonExistants = this.paiement_state
        .filter(
          (obj) =>
            !this.selectedMoyensPaiement.some(
              (moyen) => moyen.value === obj.moyen_paiement
            )
        )
        .map((obj) => obj.id);

      if (idsNonExistants) {
        this.deletePaiement(idsNonExistants);
      }
    },
    openModal(type) {
      event.preventDefault();
      if (type === "client") {
        this.$root.$emit("bv::show::modal", "clientStoreModal");
      }
      if (type === "Product") {
        this.$root.$emit("bv::show::modal", "ProductModal");
        this.open = true;
      }
      if (type === "Forfait") {
        this.$root.$emit("bv::show::modal", "forfaitModal");
      }
      if (type === "Main d'oeuvre") {
        this.$root.$emit("bv::show::modal", "mainoeuvreModal");
      }
    },
    async removeRow(step, index) {
      const rowToRemove =
        this.getdevisMonoFiche.data.groupeLigneDocument[step]?.products[index]
          ?.id;

      if (!rowToRemove) {
        this.getdevisMonoFiche.data.groupeLigneDocument[step]?.products.splice(
          index,
          1
        );
      }

      if (rowToRemove) {
        await this.deleteProduitOfGroup(rowToRemove);
        this.getdevisMonoFiche.data.groupeLigneDocument[step]?.products.splice(
          index,
          1
        );
      }

      this.addedProducts = this.addedProducts.filter(
        (item) => !(item.step === step && item.index === index)
      );

      this.addedProducts = this.addedProducts.map((item) => {
        if (item.step === step && item.index > index) {
          return { step: item.step, index: item.index - 1 };
        }
        return item;
      });
      this.setDevisMonoFiche(this.getdevisMonoFiche);
    },
    handleRemoveRow(index) {
      this.removeRow(this.step, index);
    },

    async removeGroup(index, id) {
      await this.deleteGroupe(id);
      this.getdevisMonoFiche.data.groupeLigneDocument.splice(index, 1);
      this.setDevisMonoFiche(this.getdevisMonoFiche);
    },

    addProduit(selectedItem, index, groupIndex) {
      const group = this.getdevisMonoFiche.data.groupeLigneDocument[groupIndex];
      const productType = group?.products[index]?.type;

      let updatedProduct = null;

      if (productType === "Product") {
        const product = this.getAllProducts.find(
          (p) => p.id === selectedItem.id
        );
        updatedProduct = {
          ...group?.products[index],
          ref: product.ref,
          lib: product.lib,
          fabricant: product.fabricant,
          type_ligne: product.type_ligne,
          unit_price: product.unit_price,
          type_unite: product.type_unite,
          tva: product.tva,
          desc: product.desc,
          searchInputValue: product,
          produit_id: product.id,
          totalHT: product.unit_price * group?.products[index].quantite,
        };
      } else if (productType === "Forfait") {
        const forfait = this.getAllforfaits.find(
          (f) => f.id === selectedItem.id
        );
        updatedProduct = {
          ...group?.products[index],
          ref: forfait.ref,
          lib: forfait.lib,
          unit_price: forfait.unit_price,
          type_unite: forfait.type_unite,
          tva: forfait.tva,
          type_ligne: forfait.type_ligne,
          desc: forfait.desc,
          searchInputValue: forfait,
          produit_id: forfait.id,
          totalHT: forfait.unit_price * group?.products[index].quantite,
        };
      } else if (productType === "Main d'oeuvre") {
        const mainoeuvre = this.getAllmainoeuvres.find(
          (m) => m.id === selectedItem.id
        );
        updatedProduct = {
          ...group?.products[index],
          ref: mainoeuvre.ref,
          lib: mainoeuvre.lib,
          desc: mainoeuvre.desc,
          unit_price: mainoeuvre.unit_price,
          type_ligne: mainoeuvre.type_ligne,
          type_unite: mainoeuvre.type_unite,
          tva: mainoeuvre.tva,
          searchInputValue: mainoeuvre,
          produit_id: mainoeuvre.id,
          totalHT: mainoeuvre.unit_price * group?.products[index].quantite,
        };
      }

      if (updatedProduct) {
        this.$set(group.products, index, updatedProduct);
        this.setDevisMonoFiche(this.getdevisMonoFiche);
      }
    },

    addRow(type, groupIndex) {
      const group = this.getdevisMonoFiche.data.groupeLigneDocument[groupIndex];
      const createRow = (rowType) => ({
        groupelignedocument_id: group.id,
        type: rowType,
        ref: "",
        lib: "",
        fabricant: "",
        quantite: 1,
        type_unite: null,
        unit_price: 1,
        tva: 0,
        desc: "",
        searchInputValue: null,
      });

      group.products.push(createRow(type));
    },

    handleCheckboxChange() {
      if (this.isSameTravauxAddress) {
        this.getdevisMonoFiche.data.devis.rue_travaux =
          this.getdevisMonoFiche.data.devis.client.rue || "";
        this.getdevisMonoFiche.data.devis.cp_travaux =
          this.getdevisMonoFiche.data.devis.client.cp || "";
        this.getdevisMonoFiche.data.devis.ville_travaux =
          this.getdevisMonoFiche.data.devis.client.ville || "";
      } else {
        this.updateDevis(
          "rue_travaux",
          this.getdevisMonoFiche.data.devis.rue_travaux
        );
        this.updateDevis(
          "cp_travaux",
          this.getdevisMonoFiche.data.devis.cp_travaux
        );
        this.updateDevis(
          "ville_travaux",
          this.getdevisMonoFiche.data.devis.ville_travaux
        );
      }
      this.setDevisMonoFiche(this.getdevisMonoFiche);
    },
    async handleRouteChange() {
      const id = this.$route.params.id;
      if (id) {
        await this.fetchDevisObject(id);
      }
    },

    async fetchDevisObject(id) {
      const fetchedDevisObject = await this.getoneMonodevis(id);
      fetchedDevisObject.data.devis.brouillon = 1;
      await this.setDevisMonoFiche(fetchedDevisObject);
    },
    async valideDevis(getdevisMonoFiche) {
      const { groupeLigneDocument, devis } = getdevisMonoFiche.data;

      if (
        this.selectedMoyensPaiement.length > 0 &&
        Array.isArray(groupeLigneDocument) &&
        groupeLigneDocument[0].products.length > 0 &&
        devis.client &&
        devis.debut_devis &&
        devis.fin_devis &&
        devis.cp_travaux &&
        devis.visite_technique &&
        devis.rue_travaux &&
        devis.ville_travaux
      ) {
        devis.brouillon = 0;
        getdevisMonoFiche.data.brouillon = 0;
      } else {
        devis.brouillon = 1;
        getdevisMonoFiche.data.brouillon = 1;
      }

      await this.setDevisMonoFiche(getdevisMonoFiche);
    },

    async updatedevis() {
      try {
        this.getdevisMonoFiche.data.devis.client_id = this.selectedClient?.id;
        if (this.isSuperAdmin) {
          this.getdevisMonoFiche.data.devis.entreprise_id =
            this.selectedEntreprise?.id;
        }

        this.paiement();

        for (const group of this.getdevisMonoFiche.data.groupeLigneDocument) {
          const newData = {
            id: group.id,
            // type_group: group.type_group,
            traveaux: group.traveaux,
            description: group.description,
          };

          this.updateGroupe(newData);
          for (const [
            groupIndex,
            group,
          ] of this.getdevisMonoFiche.data.groupeLigneDocument.entries()) {
            const newData = {
              id: group.id,
              traveaux: group.traveaux,
              description: group.description,
            };

            this.updateGroupe(newData);

            for (const [productIndex, product] of group.products.entries()) {
              if (product) {
                if (product.id) {
                  await this.updateProduct(product);
                  continue;
                }

                const groupelignedocumentId = String(
                  product.groupelignedocument_id
                );

                if (!product.produit_id) {
                  if (product.type === "Forfait") {
                    const res = await this.store_forfaitjson(product);
                    if (typeof res === "object" && res !== null) {
                      if (res.message && typeof res.message === "object") {
                        const allMessages = Object.entries(res.message)
                          .map(([, messages]) => {
                            if (Array.isArray(messages)) {
                              return ` ${messages.join(", ")}`;
                            }
                            return ` ${messages} `;
                          })
                          .join(" , ");

                        this.$refs.snackbar.showSnackbar(
                          `${allMessages} pour le forfait ${
                            productIndex + 1
                          } du groupe ${groupIndex + 1}`
                        );
                      } else {
                        const genericMessage =
                          res.message || "Une erreur est survenue";

                        this.$refs.snackbar.showSnackbar(
                          `${genericMessage} pour le forfait ${
                            productIndex + 1
                          } du groupe ${groupIndex + 1}`
                        );
                      }
                      return;
                    }
                    product.produit_id = res;
                  }

                  if (product.type === "Main d'oeuvre") {
                    const res = await this.store_mainoeuvreJSON(product);
                    if (typeof res === "object" && res !== null) {
                      if (res.message && typeof res.message === "object") {
                        const allMessages = Object.entries(res.message)
                          .map(([, messages]) => {
                            if (Array.isArray(messages)) {
                              return ` ${messages.join(", ")}`;
                            }
                            return ` ${messages} `;
                          })
                          .join(" , ");

                        this.$refs.snackbar.showSnackbar(
                          `${allMessages} pour le main d'oeuvre ${
                            productIndex + 1
                          } du groupe ${groupIndex + 1}`
                        );
                      } else {
                        const genericMessage =
                          res.message || "Une erreur est survenue";

                        this.$refs.snackbar.showSnackbar(
                          `${genericMessage} pour le main d'oeuvre ${
                            productIndex + 1
                          } du groupe ${groupIndex + 1}`
                        );
                      }
                      return;
                    }
                    product.produit_id = res;
                  }
                  if (product.type === "Product") {
                    const res = await this.store_productJSON(product);

                    if (typeof res === "object" && res !== null) {
                      if (res.message && typeof res.message === "object") {
                        const allMessages = Object.entries(res.message)
                          .map(([, messages]) => {
                            if (Array.isArray(messages)) {
                              return ` ${messages.join(", ")}`;
                            }
                            return ` ${messages} `;
                          })
                          .join(" , ");

                        this.$refs.snackbar.showSnackbar(
                          `${allMessages} pour le produit ${
                            productIndex + 1
                          } du groupe ${groupIndex + 1}`
                        );
                      } else {
                        const genericMessage =
                          res.message || "Une erreur est survenue";

                        this.$refs.snackbar.showSnackbar(
                          `${genericMessage} pour le produit ${
                            productIndex + 1
                          } du groupe ${groupIndex + 1}`
                        );
                      }
                      return;
                    }

                    product.produit_id = res;
                  }
                }

                if (!product.produit_id) {
                  this.$refs.snackbar.showSnackbar(
                    `Erreur: Produit ID manquant à l'index ${productIndex} du groupe ${groupIndex}`
                  );
                  return;
                }

                const produitId = String(product.produit_id);

                await this.createoneProductOfGroup({
                  ...product,
                  groupelignedocument_id: groupelignedocumentId,
                  produit_id: produitId,
                  type_ligne: product.type,
                });
              }
            }
          }
        }
        const debutDevis = new Date(
          this.getdevisMonoFiche?.data?.devis?.debut_devis
        );
        const finDevis = new Date(
          this.getdevisMonoFiche?.data?.devis?.fin_devis
        );
        const visiteTechnique = new Date(
          this.getdevisMonoFiche?.data?.devis?.visite_technique
        );
        if (visiteTechnique > debutDevis) {
          this.$refs.snackbar.showSnackbar("error_visite_debut_devis");
          this.isLoading = false;
          return;
        }
        if (debutDevis > finDevis) {
          this.$refs.snackbar.showSnackbar("error_debut_fin_devis");
          return;
        }
        const debutTravaux = new Date(
          this.getdevisMonoFiche?.data?.devis?.debut_travaux
        );
        const finTravaux = new Date(
          this.getdevisMonoFiche?.data?.devis?.fin_travaux
        );

        if (debutTravaux < debutDevis) {
          this.$refs.snackbar.showSnackbar(`error_date_devis`);

          return;
        }
        if (debutTravaux > finTravaux) {
          this.$refs.snackbar.showSnackbar(`error_date_travaux`);

          return;
        }

        this.valideDevis(this.getdevisMonoFiche);
        this.setDevisMonoFiche(this.getdevisMonoFiche);

        const reponse = await this.updatedevisMonoFiche(
          this.getdevisMonoFiche.data.devis
        );
        this.facture();
        this.$router.push("/facture/list");
        return reponse;
      } catch (error) {
        throw new Error(error);
      }
    },
    totalHT(item) {
      const totalSansRemise = item.unit_price * item.quantite;
      if (Math.round(totalSansRemise * 100) / 100 < 0) return 0;
      return Math.round(totalSansRemise * 100) / 100;
    },
    facture() {
      const fact={
        ...this.getdevisMonoFiche.data.devis,
        document_id: this.getdevisMonoFiche.data.id,
        type: "facture.acompte",
        total_type: "pourcentage",
        restht: null,
        restttc: null,
        restttc_pourcentage: null,
        modele_id: this.getdevisMonoFiche.data.devis.modele.id,
        type_facture: "facture.acompte",
        devis_id: this.getdevisMonoFiche.data.devis.fin_devis.id,
        groupes: this.getdevisMonoFiche.data.groupeLigneDocument,
      }
      this.store_facture(fact)
        .then((response) => {
          if (response) {
            const facture = response.data.data.facture;
            if (facture) {
              this.$router.push("/facture/list?id=" + facture.id);
            } else {
              this.$router.push("/facture/list");
            }
          } else alert("Erreur !");
        })
        .catch(() => {
          this.erreur = this.getFactureError;
          this.$refs["ModelError"].show();
        });
    },
  },

  computed: {
    ...mapState({
      token: (state) => state.token,
    }),
    ...mapGetters([
      "getGroupe",
      "getMondataireEntitesExternes",
      "getdevisMonoFiche",
      "getAllmainoeuvres",
      "getmainoeuvreLoading",
      "getAllforfaits",
      "getforfaitLoading",
      "getTabs",
      "getAllProducts",
      "getAllclients",
      "getOnlineUser",
      "getdevis",
      "getAllentreprises",
      "getdevisLoading",
    ]),
    initializeIsChecked() {
      return this.getdevisMonoFiche.data?.groupeLigneDocument.map(
        (group) => group.description !== ""
      );
    },
    isEditorVisibles() {
      return this.isChecked || this.group.description !== "";
    },
    filteredProducts() {
      const selectedIds = this.getdevisMonoFiche.data?.groupeLigneDocument
        .flatMap((step) => step.products)
        .filter((product) => product.type === "Product")
        .map((product) => product.produit_id);

      return this.getAllProducts.filter(
        (product) => !selectedIds.includes(product.id)
      );
    },
    filteredForfaits() {
      const selectedIds = this.getdevisMonoFiche.data?.groupeLigneDocument
        .flatMap((step) => step.products)
        .filter((product) => product.type === "Forfait")
        .map((product) => product.produit_id);

      return this.getAllforfaits.filter(
        (forfait) => !selectedIds.includes(forfait.id)
      );
    },
    filteredMainOeuvres() {
      const selectedIds = this.getdevisMonoFiche.data?.groupeLigneDocument
        .flatMap((step) => step.products)
        .filter((product) => product.type === "Main d'oeuvre")
        .map((product) => product.produit_id);

      return this.getAllmainoeuvres.filter(
        (mainoeuvre) => !selectedIds.includes(mainoeuvre.id)
      );
    },
    isBlockEnabled() {
      return this.active;
    },
    entrepriseupdated() {
      if (this.isSuperAdmin) {
        return (
          this.selectedEntreprise &&
          this.selectedEntreprise.id !== this.getOnlineUser.entreprise.id
        );
      }
      return false;
    },
    isSameTravauxAddress: {
      get() {
        return this.getdevisMonoFiche.data.devis.same_travaux_address === 1;
      },
      set(value) {
        this.getdevisMonoFiche.data.devis.same_travaux_address = value ? 1 : 0;
        this.handleCheckboxChange();
      },
    },

    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },
    isCommercialBatigo() {
      return this.getOnlineUser.role == "user.commercial-batigo";
    },
    isAssistant() {
      return this.getOnlineUser.role == "user.assistant";
    },
  },

  async created() {
    this.handleRouteChange();

    if (this.getdevisMonoFiche?.data?.devis) {
      this.initializeDateData();
      this.formatDates();
    }
    const devi_ID = this.$route.params.id;
    try {
      const data = await this.getPaiement(devi_ID);
      this.paiement_state = data;

      const moyensPaiement = Array.from(
        new Set(this.paiement_state.map((item) => item.moyen_paiement))
      );

      this.selectedMoyensPaiement = moyensPaiement
        .map((moyen) => {
          const option = this.optionspaiement.find(
            (option) => option.value === moyen
          );
          return option ? { value: option.value, text: option.text } : null;
        })
        .filter((moyen) => moyen !== null);
    } catch (error) {
      throw new Error(error);
    }
  },
  mounted() {
    this.all_mainoeuvres({ page: this.page, per_page: 100000 });
    this.all_products({ page: this.page, per_page: 100000 });
    this.all_forfaits({ page: this.page, per_page: 100000 });
    this.setup();
    this.isChecked = this.initializeIsChecked;
  },
};
</script>

<style lang="scss" scoped>
.checkbox-wrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  min-width: 1000px;
  position: relative;
  padding: 1rem;
}

.checkbox-container {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}

.ql-snow * {
  all: unset;
}
.container-wrappe {
  min-width: 1000px;
  display: flex;
  justify-content: flex-end;
}

.d-flex {
  width: 100%;
}

.vue-editor {
  min-width: 1000px;
  max-width: 100%;
  overflow-x: auto;
  white-space: normal;
}

.quillWrapper {
  background-color: white !important;
}

.colorred {
  background-color: #f8f9fa;
}
.text-container {
  max-width: 16ch;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal;
}

.bodys {
  margin: 0px 15px;
  background-color: #fff;
  box-shadow: 1px 1px 24px #00000019;
  border-radius: 5px;
  padding: 14px;
  min-height: 88vh;
}
.flex {
  display: flex;
  align-items: center;
}

.space-x-2 > *:not(:last-child) {
  margin-right: 8px;
}

.newstore {
  height: 38px;
  width: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.new {
  height: 40px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #24316d;
  color: white;
}

.marg {
  margin-top: 4.5%;
}

.position {
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.05rem 2rem;
  width: 100%;
  height: 100%;
}

.custom-input-small {
  width: 150px;
  height: 30px;
  border: 2px solid white;
  border-radius: 5px;
  padding: 5px;
  font-size: 14px;
  box-shadow: none !important;
  position: absolute;
  background-color: #fff;
}

.disabledBlock {
  opacity: 0.5;
  pointer-events: none;
  margin-bottom: 50px;
}
.page-header {
  h1 {
    width: 100%;
  }
}

.body {
  @media only screen and (max-width: 900px) {
    padding: 8px;
    width: 100%;
  }
  width: 97%;
  margin: 0 auto;
  background-color: #fff;

  border-radius: 5px;

  .containerz {
    @media only screen and (max-width: 900px) {
      padding: 8px;
      width: 100%;
      margin: 0;
    }
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 2px 6px 0px rgba(229, 229, 229, 0.75);
    padding: 42px;
  }
}
.tabs-container {
  display: flex;
  justify-content: center;
}
.newstore {
  background: #24316d;
  padding: 8px 12px;
  color: #fff;
  cursor: pointer;
  width: 30px;
  height: 30px;
  &:hover {
    background-color: #24316d !important;
  }
}
.truncated-label .label-class {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 10px;
}
b-dropdown {
  background-color: #24316d !important;
}
.btn-secondary {
  color: #fff;
  background-color: #24316d !important;
  border-color: #24316d !important;
}
.header-item {
  flex: 1;
}
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 2px solid #ddd;
}

.btn-primary.dropdown-toggle {
  font-size: 14px;
}
.custom-placeholder::placeholder {
  font-size: 13px;
}
.input-wrapper:not(:last-child) {
  margin-right: 10px;
}

.form-input {
  font-size: 13px;
  height: 41.1px !important;
  width: 100%;
}
.button-pre-style {
  color: white;
  background-color: #24316d !important;
  box-shadow: none;
  border-radius: 5px 5px 5px 5px;
}

.custom-input {
  box-shadow: none !important;
  width: 100%;
}

.row-item {
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 2px solid #ddd;
  background-color: #24316d;
  color: white;
}

.borders {
  display: block;
  width: 100%;
  margin-top: 20px;
  box-sizing: border-box;
}

.scroll-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}
.scroll-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}
.scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.scroll-container::-webkit-scrollbar {
  display: none;
}
.scroll-container {
  width: 100%;

  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
}
.scroll-container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: transparent;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.scroll-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.small-spinner .spinner-border {
  width: 1rem;
  height: 1rem;
}
.content-wrapper {
  min-width: 1000px;
}

.header,
.row-item {
  display: flex;
  min-width: 100px;
}

.input-wrapper {
  display: flex;
  align-items: center;
  margin: 5px;
}
.border_pointe {
  margin: 10px;
  border: 2px dashed #24316d;
  position: static;
}
.background-white {
  display: inline-block;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  position: absolute;
  top: -1rem;
  left: 0;
  right: 0;
  z-index: 1;
  text-align: center;
}
</style>